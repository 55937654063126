import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    report: null,
  },
  reducers: {
    setReport: (state, action) => {
      state.report = action.payload.report;
    },
  }
});

export const {
  setReport,
} = reportSlice.actions;

export const getReportAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/risk/control-gaps?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setReport({ report: res.data }));
};

export const selectReport = (state) => state.report.report;

export default reportSlice.reducer;
