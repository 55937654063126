import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Input, Layout, Segmented, Select, Table } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import useLocalStorageState from 'use-local-storage-state';

import AddColumnModal from './AddColumnModal';
import ExcelExport from './ExcelExport';
import SortableSelect from './SortableSelect';

const { Content, Sider } = Layout;
const { TextArea } = Input;

const EditableContext = React.createContext(null);

const getMinWidth = (textLength = 0) => {
  if (textLength > 1000) {
    return 600;
  }
  if (textLength > 400) {
    return 400;
  }
  if (textLength > 250) {
    return 250;
  }
  return 0;
};

const spaced = (name) => {
  return name.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};

const EditableRow = ({ index, ...props }) => {

  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const CellValue = ({ value }) => {
  if (typeof value[1] === 'boolean') {
    return value[1] ? 'true' : 'false';
  }
  return value[1];
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  onSave,
  ...restProps
}) => {

  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      onSave({
        record,
        value: Object.keys(values).map(key => ({
          key,
          value: values[key],
        }))[0],
      });
    } catch (err) {
      console.log('Save failed:', err);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          minWidth: 150,
        }}
        name={dataIndex}
      // rules={[
      //   {
      //     required: true,
      //     message: `${title} is required.`,
      //   },
      // ]}
      >
        <TextArea
          autoSize={{ minRows: 1, maxRows: 14 }}
          ref={inputRef}
          // onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
      // style={{
      //   minWidth: getMinWidth(children[1]?.length),
      //   paddingInlineEnd: 24,
      // }}
      // onClick={toggleEdit}
      >
        <CellValue value={children} />
      </div>
    );
  }
  // return <td {...restProps}>
  //   <div style={{ minWidth: getMinWidth(children[1]?.length) }}>
  //     {childNode}
  //   </div>
  // </td>;
  return (
    <td
      {...restProps}
      onClick={() => {
        if (!editing) {
          toggleEdit();
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      {childNode}
    </td>
  );
};

export function EditableTable({
  dataSource,
  defaultColumns,
  onAdd,
  onDelete,
  onSave,
  onChangeTable,
  tableType,
  setTableType,
  tableView,
  onAddColumn,
  onSaveProperty,
  nodeOptions,
  onQuery,
}) {

  const [formOpen, setFormOpen] = useState(false);
  const [maxTagCount, setMaxTagCount] = useState('responsive');
  const [newColumn, setNewColumn] = useState({});
  const [selectedColumns, setSelectedColumns] = useLocalStorageState('selected-columns', { defaultValue: [] });
  const [selectedNodes, setSelectedNodes] = useLocalStorageState('selected-nodes', { defaultValue: [] });
  const [selectedNodeInstances, setSelectedNodeInstances] = useLocalStorageState('selected-node-instances', { defaultValue: {} });
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      hideOnSinglePage: true,
      position: ['topLeft'],
      size: 'small',
    },
    filters: {},
  });
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [facetsCollapsed, setFacetsCollapsed] = useLocalStorageState('facets-collapsed', { defaultValue: true });

  const columnOptions = defaultColumns
    .filter(c => !['label', 'id', 'type'].includes(c.title))
    .map(c => ({
      label: c.title,
      value: c.dataIndex,
    }));

  const nodeLabelOptions = useMemo(() => {
    const typeCol = tableView === 'nodes' ? 'type' : 'source_type';
    const nodes = [...new Set(dataSource.map(n => n[typeCol]))];
    const list = nodes.map(n => ({
      label: n,
      value: n,
    }));
    list.sort((a, b) => a.label < b.label ? -1 : 1);
    return list;
  }, [dataSource, tableView]);

  const nodeLabels = useMemo(() => {
    return nodeLabelOptions.map(n => n.label);
  }, [nodeLabelOptions]);

  const data = useMemo(() => {
    const typeCol = tableView === 'nodes' ? 'type' : 'source_type';
    return dataSource
      .filter((row) => !selectedNodes.length || selectedNodes.includes(row[typeCol]))
      // .filter((row) => {
      //   if (isObjectEmpty(selectedNodeInstances)) {
      //     return true;
      //   }
      //   const values = selectedNodeInstances?.[row.type] || [];
      //   if (values.length) {
      //     return values.includes(row.id);
      //   }
      //   return false;
      // })
      .map((row) => {
        if (selectedColumns.length) {
          return ['label', 'id', 'type', ...selectedColumns].reduce((a, col, i) => {
            a[col] = row[col];
            return a;
          }, {});
        }
        return Object.keys(row).reduce((a, col, i) => {
          a[col] = row[col];
          return a;
        }, {});
      });
  }, [dataSource, selectedColumns, selectedNodes, selectedNodeInstances, tableView]);

  const exportedData = useMemo(() => {
    return data

      // TODO reset between table type changes
      // .filter((row) => {
      //   const targetTypes = tableParams?.filters?.target_type;
      //   if (targetTypes) {
      //     return targetTypes.includes(row.target_type);
      //   }
      //   return true;
      // })

      .map((row) => Object.entries(row).reduce((a, [k, v]) => {
        if (k === 'Topic') {
          a[k] = v?.split(', ').map((x, i) => (i + 1) + '. ' + x.slice(1, -1)).join('\n');
        } else if (typeof v === 'boolean') {
          a[k] = v ? 'true' : 'false';
        } else {
          a[k] = v;
        }
        return a;
      }, {}));
  }, [data, tableParams]);

  useEffect(() => {
    if (!selectedColumns?.length) {
      setSelectedColumns(columnOptions.map(c => c.value));
    }
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleAddColumn = (values) => {
    const name = values.values.name;
    setNewColumn(cur => ({ ...cur, name }));
    setFormOpen(false);
    const index = newColumn.index;
    if (selectedColumns.length) {
      setSelectedColumns(cur => [...cur.slice(0, index), name, ...cur.slice(index + 1)]);
    } else {
      const cols = columnOptions.map(c => c.value);
      setSelectedColumns(cur => [...cols.slice(0, index), name, ...cols.slice(index + 1)]);
    }
    onAddColumn(values);
  };

  const handleRefresh = () => {
    const aliases = 'defghijklmn';
    const filters = [];
    const params = {};
    let i = 0;
    console.log('selectedNodeInstances:', selectedNodeInstances)
    for (const label of Object.keys(selectedNodeInstances)) {
      const ids = selectedNodeInstances[label];
      console.log('label:', label)
      console.log('ids:', ids)
      if (ids?.length) {
        const alias = aliases.charAt(i);
        const param = label.toLowerCase() + '_ids';
        const filter = `MATCH (n)-[*]-(${alias}:${label}) WHERE ${alias}.id IN $${param}`;
        filters.push(filter);
        params[param] = ids;
      }
      i += 1;
    }
    onQuery({ filters, params });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {
      pagination,
      filters,
      ...sorter,
    };
    setTableParams(params);
    onChangeTable(params);
  };

  const columns = useMemo(() => {
    let cols;
    if (selectedColumns.length) {
      cols = ['label', 'id', 'type', ...selectedColumns].reduce((a, c) => {
        const col = defaultColumns.find(x => x.dataIndex === c);
        a.push(col);
        return a;
      }, []);
    } else {
      cols = defaultColumns;
    }
    return cols
      .filter(col => col)
      .map((col, i) => {
        if (!col.editable) {
          return {
            ...col, fixed: i === 0,
            render: (_, record) => {
              if (nodeLabels.includes(col.dataIndex)) {
                const entries = record[col.dataIndex]?.split(', ') || [];
                entries.sort();
                return (
                  <ol>
                    {entries.map(x => x.slice(1, -1)).map((x, i) => (
                      <li key={col.dataIndex + '-' + i}>{x}</li>
                    ))}
                  </ol>
                );
              }
              if (typeof record[col.dataIndex] === 'boolean') {
                return record[col.dataIndex] ? 'true' : 'false';
              }
              return record[col.dataIndex];
            },
            title: (
              <div className="table-title" style={{ position: 'relative' }}>
                {i > 1 ?
                  <div className="add-column-btn" style={{ position: 'absolute', right: -29, top: -41 }}>
                    <Button
                      size="small"
                      type="text"
                      icon={<PlusOutlined />}
                      onMouseEnter={() => setSelectedColumnIndex(i)}
                      onMouseLeave={() => setSelectedColumnIndex(-1)}
                      onClick={() => {
                        setFormOpen(true);
                        setNewColumn({ index: i + 1 });
                      }}
                    />
                  </div>
                  : null
                }
                <div>{col.title}</div>
              </div>
            ),
            className: i === selectedColumnIndex ? 'selected' : '',
          };
        }
        return {
          ...col,
          fixed: i === 0,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            onSave: onSaveProperty,
          }),
          title: (
            <div className="table-title" style={{ position: 'relative' }}>
              {i > 1 ?
                <div className="add-column-btn" style={{ position: 'absolute', right: -29, top: -41 }}>
                  <Button
                    size="small"
                    type="text"
                    icon={<PlusOutlined />}
                    onMouseEnter={() => setSelectedColumnIndex(i)}
                    onMouseLeave={() => setSelectedColumnIndex(-1)}
                    onClick={() => {
                      setFormOpen(true);
                      setNewColumn({ index: i - 2 });
                    }}
                  />
                </div>
                : null
              }
              <div>{col.title}</div>
            </div>
          ),
        };
      });
  }, [defaultColumns, newColumn, selectedColumnIndex, selectedColumns]);

  return (
    <>
      <AddColumnModal
        nodeOptions={nodeLabelOptions}
        onCancel={() => setFormOpen(false)}
        onSubmit={handleAddColumn}
        open={formOpen}
      />
      <Layout>
        <Sider
          collapsible
          collapsed={facetsCollapsed}
          collapsedWidth={0}
          trigger={null}
          style={{
            background: '#FBFBFB',
            marginRight: facetsCollapsed ? 0 : 10,
          }}
          width={250}
          theme="light"
        >
          <div style={{ fontWeight: 600, fontSize: '17px', paddingTop: 1 }}>Facets</div>
          <div style={{ paddingBottom: 16 }}>
            <div style={{ fontWeight: 600, padding: '8px 0' }}>Node Types</div>
            <Select
              allowClear
              maxTagCount="responsive"
              mode="multiple"
              options={nodeLabelOptions}
              placeholder="Select row types to display"
              size="small"
              style={{ width: 225 }}
              value={selectedNodes}
              onChange={setSelectedNodes}
            />
          </div>
          {Object.entries(nodeOptions).map(([label, options]) => (
            <div style={{ paddingBottom: 16 }}>
              <div style={{ fontWeight: 600, padding: '8px 0' }}>{spaced(label)}</div>
              <Select
                allowClear
                maxTagCount="responsive"
                mode="multiple"
                options={options}
                placeholder="Select entries to display"
                size="small"
                style={{ width: 225 }}
                value={selectedNodeInstances[label]}
                onChange={(values) => setSelectedNodeInstances(cur => ({ ...cur, [label]: values }))}
              />
            </div>
          ))}
          <Button type="primary" size="small"
            // disabled={isObjectEmpty(setSelectedNodeInstances)}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </Sider>
        <Content>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8, paddingTop: 0 }}>
            <Button
              size="small"
              type="text"
              icon={facetsCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setFacetsCollapsed(cur => !cur)}
              style={{ background: 'rgba(60, 61, 65, 0.06)' }}
            >
              Facets
            </Button>
            <SortableSelect
              allowClear
              className={maxTagCount ? '' : 'expanded'}
              maxTagCount={maxTagCount}
              mode="multiple"
              options={columnOptions}
              placeholder="Select columns to display"
              size="small"
              style={{ width: 367 }}
              value={selectedColumns}
              onChange={setSelectedColumns}
            />
            <Button
              size="small"
              type="text"
              icon={<SortAscendingOutlined />}
              onClick={() => setMaxTagCount(cur => cur ? null : 'responsive')}
              style={{ marginLeft: -4 }}
            />
            <Segmented
              onChange={setTableType}
              value={tableType}
              size="small"
              style={{ background: 'rgba(0, 0, 0, 0.25)' }}
              options={[
                {
                  label: 'Cell List',
                  value: 'cell-list',
                },
                {
                  label: 'Adjacency Matrix',
                  value: 'adjacency-matrix',
                },
              ]}
            />
            <ExcelExport
              data={exportedData}
              filename={'export'}
            />
          </div>
          <div id="graph-table" style={{ marginTop: data.length ? 0 : 24 }}>
            <Table
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={data}
              columns={columns}
              onChange={handleTableChange}
              pagination={tableParams.pagination}
            />
          </div>
          {onAdd ?
            <Button block
              onClick={onAdd}
              type="dashed"
              style={{
                marginBottom: 16,
                marginTop: 16,
              }}
            >
              Add a row
            </Button>
            : null
          }
        </Content>
      </Layout>
    </>
  );
}

const isObjectEmpty = (obj) => {
  if (!obj) return true;
  for (const node of Object.values(obj)) {
    if (node?.length) {
      return false;
    }
  }
  return true;
};
