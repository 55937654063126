// https://github.com/ant-design/ant-design/issues/9459
import React from 'react';
import {
  Droppable,
  DragDropContext,
  Draggable,
} from 'react-beautiful-dnd';
import { Select } from 'antd';

import TagItem from './TagItem';

const getItemStyle = (
  isDragging,
  draggableStyle,
) => ({
  userSelect: 'none',
  opacity: isDragging ? 0.5 : 1,
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const SortableSelect = ({
  onChange,
  value,
  tagRender,
  ...props
}) => {

  const onDragEnd = (result) => {
    const {
      destination,
      source: { index: dragIndex },
    } = result;

    if (destination) {
      const items = reorder(value, dragIndex, destination.index);

      onChange?.(items);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Select
              style={{ width: '100%' }}
              tagRender={(props) => (
                <Draggable
                  key={props.value}
                  draggableId={props.value || 'foo'}
                  index={value.findIndex((item) =>
                    props.labelInValue ? item.value === props.value : item === props.value,
                  )}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {tagRender ? tagRender(props) : <TagItem {...props} />}
                    </div>
                  )}
                </Draggable>
              )}
              onChange={onChange}
              value={value}
              {...props}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableSelect;
