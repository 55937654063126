import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Space, Table } from 'antd';
import {
  FileExcelOutlined,
  FileMarkdownOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  GoogleOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import hr from '@tsmx/human-readable';
import useLocalStorageState from 'use-local-storage-state';
import globToRegExp from 'glob-to-regexp';
import intersection from 'lodash.intersection';

import { ContentView } from '../../components/ContentView';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import { getHumanFriendlyDeltaString } from '../../dateUtils';
import { getExtension } from '../../pathUtils';
import {
  getSettingByKeyAsync,
  selectSettings,
} from '../settings/settingsSlice';

import {
  applyActivityAsync,
  applyExtractClaimsActivityAsync,
  applyExtractQuotesActivityAsync,
  applyExtractReferencesActivityAsync,
  applyExtractThesisStatementActivityAsync,
  applyExtractVideosActivityAsync,
  deleteUploadsAsync,
  getUploadsAsync,
  selectLoading,
  selectUploaded,
  selectUploading,
  selectUploads,
} from './fileUploaderSlice';

const getDocIcon = (ext) => {
  switch (ext) {
    case 'csv':
    case 'xlsx':
      return <FileExcelOutlined style={{
        color: '#217346',
        fontSize: '1.5em',
      }} />;

    case 'doc':
    case 'docx':
      return <FileWordOutlined style={{
        color: '#2b579a',
        fontSize: '1.5em',
      }} />;

    case 'md':
      return <FileMarkdownOutlined style={{
        color: '#094ab2',
        fontSize: '1.5em',
      }} />;

    case 'pdf':
      return <FilePdfOutlined style={{
        color: '#F40F02',
        fontSize: '1.5em',
      }} />;

    case 'ppt':
    case 'pptx':
      return <FilePptOutlined style={{
        color: '#d24726',
        fontSize: '1.5em',
      }} />;

    case 'txt':
      return <FileTextOutlined style={{
        color: 'rgba(0, 0, 0, 0.88)',
        fontSize: '1.5em',
      }} />;

    case 'youtube':
      return <YoutubeOutlined style={{
        color: 'red',
        fontSize: '1.5em',
      }} />;

    case 'gdrive':
      return <GoogleOutlined style={{
        color: 'rgb(66, 133, 244)',
        fontSize: '1.5em',
      }} />;

    default:
      return <FileOutlined style={{
        color: 'rgba(0, 0, 0, 0.88)',
        fontSize: '1.5em',
      }} />;
  }
};

const agentTableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, { name }) => (
      <div style={{ width: 250 }}>{name}</div>
    )
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
];

const activityTableData = [
  {
    key: 'extract-claims',
    name: 'Extract Claims',
    description: 'Extract claims.',
  },
  {
    key: 'extract-controls',
    name: 'Extract Controls',
    description: 'Extract controls from Excel.',
  },
  {
    key: 'extract-control-objectives',
    name: 'Extract Control Objectives',
    description: 'Extract control objectives from Excel.',
  },
  {
    key: 'extract-document',
    name: 'Extract Document',
    description: 'Extract document.',
  },
  {
    key: 'extract-graph',
    name: 'Extract Graph',
    description: 'Extract graph from document.',
  },
  {
    key: 'extract-graph-from-json',
    name: 'Extract Graph from JSON',
    description: 'Extract graph from a JSON document.',
  },
  {
    key: 'extract-images',
    name: 'Extract Images',
    description: 'Extract images from document.',
  },
  {
    key: 'extract-nodes',
    name: 'Extract Nodes',
    description: 'Extract graph nodes from Excel.',
  },
  {
    key: 'extract-relationships',
    name: 'Extract Relationships',
    description: 'Extract graph relationships from Excel.',
  },
  {
    key: 'extract-quotes',
    name: 'Extract Quotes',
    description: 'Extract quotes.',
  },
  {
    key: 'extract-references',
    name: 'Extract References',
    description: 'Extract references.',
  },
  {
    key: 'extract-sub-mandates',
    name: 'Extract Sub-mandates',
    description: 'Extract sub-mandates.',
  },
  {
    key: 'extract-thesis-statement',
    name: 'Extract Thesis Statement',
    description: 'Extract thesis statement.',
  },
  {
    key: 'extract-transcript',
    name: 'Extract Transcript',
    description: 'Extract transcript.',
  },
  {
    key: 'extract-videos',
    name: 'Extract YouTube Videos',
    description: 'Extract YouTube videos.',
  },
];

export function UploadsList({ }) {

  const [isAgentModalOpen, setIsAgentModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useLocalStorageState('documents-list-page', { defaultValue: 1 });
  const [selectedActivityKeys, setSelectedActivityKeys] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const settings = useSelector(selectSettings);
  const uploads = useSelector(selectUploads);
  const uploaded = useSelector(selectUploaded);
  const uploading = useSelector(selectUploading);

  const { selectedWorkspace } = useContext(WorkspaceContext);

  const data = useMemo(() => {
    const list = Object.values(uploads).map((doc) => {
      let name, ext;
      if (doc.type === 'document') {
        name = doc.filename;
        ext = getExtension(doc.filename);
      } else if (doc.type === 'youtube') {
        name = doc.query;
        ext = 'youtube';
      } else if (doc.type === 'gdrive') {
        name = doc.name;
        ext = 'gdrive';
      }
      return {
        key: doc.id,
        id: doc.id,
        name,
        size: doc.size,
        lastModified: doc.modified,
        ext,
        filename: doc.filename,
        type: doc.type,
      };
    });
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [uploads]);

  const upload = useMemo(() => {
    if (!selectedId) return null;
    return uploads[selectedId];
  }, [selectedId, uploads]);

  const ext = useMemo(() => {
    if (!upload) return null;
    getExtension(upload.filename);
  }, [upload]);

  const documentMap = useMemo(() => {
    const setting = Object.values(settings).find(s => s.key === 'documentmap');
    return setting?.value || {};
  }, [settings]);

  const activityDataSource = useMemo(() => {
    const result = {};
    for (const key of selectedRowKeys) {
      const upload = uploads[key];
      if (upload) {
        for (const [pattern, activities] of Object.entries(documentMap)) {
          if (upload.type === 'document') {
            const re = globToRegExp(pattern);
            if (re.test(upload.filename)) {
              result[key] = activities;
            }
          } else if (upload.type === 'youtube') {
            if (pattern === 'youtube') {
              result[key] = activities;
            }
          } else {
            const re = globToRegExp(pattern);
            if (re.test(upload.filename) || pattern.toLowerCase() === upload.filename?.toLowerCase()) {
              result[key] = activities;
            }
          }
        }
      }
    }
    const keys = intersection(...Object.values(result));
    return activityTableData.filter(a => keys.includes(a.key));
  }, [documentMap, selectedRowKeys]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getSettingByKeyAsync({ key: 'documentmap', workspaceId }));
      dispatch(getUploadsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (uploaded) {
      setSelectedRowKeys([]);
    }
  }, [uploaded]);

  const handleApply = () => {
    setIsAgentModalOpen(true);
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setSelectedId(null);
  };

  const onAgentModalCancel = () => {
    setIsAgentModalOpen(false);
    setSelectedActivityKeys([]);
    setSelectedRowKeys([]);
  };

  const onDelete = () => {
    const deleteList = [];
    for (const key of selectedRowKeys) {
      const found = uploads[key];
      if (found) {
        deleteList.push(found);
      }
    }
    dispatch(deleteUploadsAsync({
      uploads: deleteList,
      workspaceId: selectedWorkspace.id,
    }));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const showContent = (id) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const applyActivity = () => {
    const activity = selectedActivityKeys[0];
    if (activity === 'extract-videos') {
      const key = selectedRowKeys[0];
      const upload = uploads[key];
      dispatch(applyExtractVideosActivityAsync({
        query: upload.query,
        workspaceId: selectedWorkspace.id,
        domain: selectedWorkspace.key,
      }));
    } else if (activity === 'extract-claims') {
      const key = selectedRowKeys[0];
      const upload = uploads[key];
      dispatch(applyExtractClaimsActivityAsync({
        rootFolderId: upload.rootFolderId,
        pattern: upload.pattern,
        workspaceId: selectedWorkspace.id,
      }));
    } else if (activity === 'extract-quotes') {
      const key = selectedRowKeys[0];
      const upload = uploads[key];
      dispatch(applyExtractQuotesActivityAsync({
        rootFolderId: upload.rootFolderId,
        pattern: upload.pattern,
        workspaceId: selectedWorkspace.id,
        domain: selectedWorkspace.key,
      }));
    } else if (activity === 'extract-references') {
      const key = selectedRowKeys[0];
      const upload = uploads[key];
      dispatch(applyExtractReferencesActivityAsync({
        rootFolderId: upload.rootFolderId,
        pattern: upload.pattern,
        workspaceId: selectedWorkspace.id,
      }));
    } else if (activity === 'extract-thesis-statement') {
      const key = selectedRowKeys[0];
      const upload = uploads[key];
      dispatch(applyExtractThesisStatementActivityAsync({
        rootFolderId: upload.rootFolderId,
        pattern: upload.pattern,
        workspaceId: selectedWorkspace.id,
      }));
    } else {
      const objects = Object.values(uploads).filter(u => selectedRowKeys.includes(u.id));
      dispatch(applyActivityAsync({
        activity,
        domain: selectedWorkspace.key,
        objectNames: objects.map(o => o.objectName),
        workspaceId: selectedWorkspace.id,
      }));
    }
    setIsAgentModalOpen(false);
    setSelectedActivityKeys([]);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { id, name, type }) => (
        <a href='#'
          onClick={() => {
            if (type === 'document') {
              showContent(id);
            } else {
              navigate(`/uploads/${id}`);
            }
          }}
        >
          {name}
        </a>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'type',
      className: 'col-hdr-nowrap',
    },
    {
      title: 'Type',
      dataIndex: 'ext',
      align: 'center',
      render: (_, { ext }) => getDocIcon(ext),
      className: 'col-hdr-nowrap',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      align: 'right',
      render: (_, { size }) => {
        if (size) {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{hr.fromBytes(size)}</span>
          );
        }
        return null;
      },
      className: 'col-hdr-nowrap',
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      render: (_, { lastModified }) => (
        <span style={{ whiteSpace: 'nowrap' }}>{getHumanFriendlyDeltaString(lastModified)}</span>
      ),
      className: 'col-hdr-nowrap',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 225,
      render: (_, record) => (
        <Space direction="vertical">
          <Space size="small">
            {record.type === 'document' ?
              <Button type="link"
                style={{ paddingLeft: 0 }}
                onClick={() => showContent(record.id)}
              >
                Preview
              </Button>
              : null
            }
            {['gdrive', 'youtube'].includes(record.type) ?
              <Button type="link"
                style={{ paddingLeft: 0 }}
                onClick={() => navigate(`/uploads/${record.id}`)}
              >
                Edit
              </Button>
              : null
            }
          </Space>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const agentRowSelection = {
    getCheckboxProps: (record) => ({
      // Column configuration not to be checked
      name: record.name,
    }),
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedActivityKeys(selectedRowKeys);
    },
    selectedRowKeys: selectedActivityKeys,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Modal
        open={isModalOpen}
        title="Content Preview"
        width={ext === 'pdf' ? 643 : '70%'}
        styles={{
          body: { height: 500, overflowY: 'auto' },
        }}
        onCancel={onCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <ContentView upload={upload} />
      </Modal>
      <Modal
        open={isAgentModalOpen}
        title="Apply Activity"
        width={800}
        onCancel={onAgentModalCancel}
        onOk={applyActivity}
        okText="Apply"
      >
        <Table
          columns={agentTableColumns}
          dataSource={activityDataSource}
          pagination={false}
          rowSelection={{
            type: 'radio',
            ...agentRowSelection,
          }}
        />
      </Modal>
      <div style={{ marginTop: 20 }}>
        <p style={{ marginBottom: 16 }}>
          Knowledge is acquired from data sources that may include documents, spreadsheets,
          or video content.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginBottom: 16 }}>
          <Button type="primary"
            disabled={!hasSelected}
            loading={uploading}
            onClick={handleApply}
          >
            Apply Activity
          </Button>
          <Button danger type="primary" disabled={!hasSelected} onClick={onDelete}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            current: page,
            hideOnSinglePage: true,
            onChange: (page, pageSize) => setPage(page),
          }}
          rowClassName="document-list-row"
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
