import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    results: null,
  },
  reducers: {
    setResults: (state, action) => {
      state.results = action.payload.results;
    },
  }
});

export const {
  setResults,
} = searchSlice.actions;

export const searchAsync = ({ query, excludeTypes, includeTypes, workspaceId }) => async (dispatch) => {
  const url = '/api/documents/query';
  const res = await http.post(url, { q: query, excludeTypes, includeTypes, workspaceId });
  dispatch(setResults({ results: res.data }));
};

export const selectResults = (state) => state.search.results;

export default searchSlice.reducer;
