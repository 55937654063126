import React from 'react';
import { Button } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const ExcelExport = ({ data, filename }) => {

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${filename}.xlsx`);
  };

  return (
    <Button
      // icon={<FileExcelOutlined />}
      onClick={exportToExcel}
      size="small"
      style={{ zIndex: 100 }}
      type="default"
    >
      Export to Excel
    </Button>
  );
}

export default ExcelExport;