import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const notebooksSlice = createSlice({
  name: 'notebooks',
  initialState: {
    loaded: false,
    loading: false,
    notebooks: {},
  },
  reducers: {
    removeNotebooks: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.notebooks[id];
      }
    },
    resetNotebooks: (state, action) => {
      state.notebooks = {};
    },
    setNotebooks: (state, action) => {
      for (const item of action.payload.notebooks) {
        state.notebooks[item.id] = item;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeNotebooks,
  resetNotebooks,
  setNotebooks,
  startLoad,
} = notebooksSlice.actions;

export const getNotebooksAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  dispatch(resetNotebooks());
  let url = `/api/notebooks?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNotebooks({ notebooks: res.data }));
};

export const getNotebookAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/notebooks/${id}`;
  const res = await http.get(url);
  const notebook = res.data;
  dispatch(setNotebooks({ notebooks: notebook ? [notebook] : [] }));
};

export const getNotebookByNameAsync = ({ name, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/notebooks/names/${name}?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  const notebook = res.data;
  dispatch(setNotebooks({ notebooks: notebook ? [notebook] : [] }));
};

export const createNotebookAsync = ({ values }) => async (dispatch) => {
  const url = '/api/notebooks';
  const res = await http.post(url, values);
  dispatch(setNotebooks({ notebooks: [res.data] }));
};

export const updateNotebookAsync = ({ id, values }) => async (dispatch) => {
  const url = `/api/notebooks/${id}`;
  const res = await http.put(url, values);
  dispatch(setNotebooks({ notebooks: [res.data] }));
};

export const deleteNotebooksAsync = ({ ids }) => async (dispatch) => {
  const url = `/api/notebooks?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeNotebooks({ ids }));
};

export const selectLoaded = (state) => state.notebooks.loaded;

export const selectLoading = (state) => state.notebooks.loading;

export const selectNotebooks = (state) => state.notebooks.notebooks;

export default notebooksSlice.reducer;
