import React from 'react';
import {
  Form,
  Input,
  Select,
} from 'antd';

export default function PropertyForm({ form, nodeOptions }) {

  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Name"
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Data Type"
        name="dataType"
      >
        <Select
          allowClear
          options={[
            {
              label: 'Tag',
              value: 'tag',
            },
            {
              label: 'String',
              value: 'string',
            },
            {
              label: 'String List',
              value: 'string_list',
            },
            {
              label: 'String Enum',
              value: 'enum',
            },
            {
              label: 'String List Enum',
              value: 'list_enum',
            },
            {
              label: 'Integer',
              value: 'int',
            },
            {
              label: 'Float',
              value: 'float',
            },
            {
              label: 'Boolean',
              value: 'boolean',
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Applicable Nodes"
        name="nodeTypes"
      >
        <Select
          allowClear
          options={nodeOptions}
          mode="multiple"
        />
      </Form.Item>
    </Form>
  );
}