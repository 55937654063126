import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState: {
    correlationId: null,
    loaded: false,
    loading: false,
    metrics: {},
    uploaded: false,
    uploading: false,
  },
  reducers: {
    removeMetrics: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.metrics[id];
      }
    },
    resetMetrics: (state) => {
      state.metrics = {};
    },
    setCorrelationId: (state, action) => {
      state.correlationId = action.payload.correlationId;
    },
    setMetrics: (state, action) => {
      for (const metric of action.payload.metrics) {
        state.metrics[metric.id] = metric;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
    startUpload: (state) => {
      state.uploading = true;
      state.uploaded = false;
    },
    uploaded: (state) => {
      state.uploaded = true;
      state.uploading = false;
    },
  }
});

export const {
  removeMetrics,
  resetMetrics,
  setCorrelationId,
  setMetrics,
  startLoad,
  startUpload,
  uploaded,
} = metricsSlice.actions;

export const getMetricsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/metrics?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setMetrics({ metrics: res.data }));
};

export const getDomainMetricsAsync = ({ domain, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  dispatch(resetMetrics());
  const url = `/api/metrics?domain=${domain}&workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setMetrics({ metrics: res.data }));
};

export const getMetricAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/metrics/${id}`;
  const res = await http.get(url);
  dispatch(setMetrics({ metrics: [res.data] }));
};

export const createMetricAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/metrics';
  const res = await http.post(url, values);
  dispatch(setMetrics({ metrics: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const updateMetricAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/metrics/${id}`;
  const res = await http.put(url, values);
  dispatch(setMetrics({ metrics: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const deleteMetricsAsync = (ids) => async (dispatch) => {
  const url = `/api/metrics?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeMetrics({ ids }));
};

export const fileUploadAsync = ({ correlationId, file, workspaceId }) => async (dispatch) => {
  dispatch(startUpload());
  const form = new FormData();
  form.append('correlationId', correlationId);
  form.append('file', file.originFileObj);
  form.append('workspaceId', workspaceId);
  const res = await http.post('/api/metrics/import', form);
  dispatch(setMetrics({ metrics: res.data }));
  dispatch(setCorrelationId({ correlationId }));
  dispatch(uploaded());
};

export const selectMetrics = (state) => state.metrics.metrics;

export const selectLoaded = (state) => state.metrics.loaded;

export const selectLoading = (state) => state.metrics.loading;

export const selectCorrelationId = (state) => state.metrics.correlationId;

export const selectUploaded = (state) => state.metrics.uploaded;

export const selectUploading = (state) => state.metrics.uploading;

export default metricsSlice.reducer;
