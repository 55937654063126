export const makeString = (object) => {
  if (object == null || typeof object === undefined) return '';
  return String(object);
};

export const escapeRegExp = (str) => {
  return makeString(str).replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');
};

export const defaultToWhiteSpace = (characters) => {
  if (characters == null) {
    return '\\s';
  } else if (characters.source) {
    return characters.source;
  } else {
    return '[' + escapeRegExp(characters) + ']';
  }
};

const nativeTrim = String.prototype.trim;

export const trim = (str, characters) => {
  str = makeString(str);
  if (!characters && nativeTrim) {
    return nativeTrim.call(str);
  }
  characters = defaultToWhiteSpace(characters);
  return str.replace(new RegExp('^' + characters + '+|' + characters + '+$', 'g'), '');
};

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const capitalize = (str, lowercaseRest) => {
  str = makeString(str);
  const remainingChars = !lowercaseRest ? str.slice(1) : str.slice(1).toLowerCase();
  return str.charAt(0).toUpperCase() + remainingChars;
};

export const ellipsis = (str, n = 20) => {
  if (!str) return null;
  const title = str.trim();
  if (title.length > n) {
    return title.substring(0, n) + '...';
  }
  return title;
};

export const slugify = (str) => {
  if (!str) return 'undefined';
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
};

export const underscored = (str) => {
  return trim(str)
    .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
    .replace(/[-\s]+/g, '_')
    .toLowerCase();
};

export const humanize = (str) => {
  return trim(underscored(str).replace(/_/g, ' '));
};

export const convertToPyValues = (values) => {
  return Object.entries(values).reduce((a, [k, v]) => {
    a[camelToSnakeCase(k)] = v;
    return a;
  }, {});
};
