import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  queryAsync,
  selectMessages,
  setMessages,
} from '../features/chat/chatSlice';
import { Chat } from './Chat';

export function ChatView({ onChange, value, selectMultiple, selectable, workspaceId, domain, loading }) {

  const [isInitialized, setIsInitialized] = useState(false);
  const [msgs, setMsgs] = useState(value || []);

  const messages = useSelector(selectMessages);

  useEffect(() => {
    if (isInitialized) {
      setMsgs(messages);
      if (onChange) {
        onChange(messages);
      }
    } else {
      setIsInitialized(true);
    }
  }, [messages]);

  const dispatch = useDispatch();

  const handleChatSubmit = (values) => {
    const { messages } = values;
    const content = messages[messages.length - 1].content;
    let args = {
      messages,
      q: content,
      workspaceId,
      domain,
    };
    dispatch(queryAsync(args));
  };

  const handleDelete = (keys) => {
    const filtered = msgs.filter(m => {
      if (m.role === 'assistant') {
        const key = m.content[0].key;
        return !keys.includes(key);
      }
      return !keys.includes(m.key);
    });
    dispatch(setMessages({ messages: filtered }));
  };

  const handleReset = () => {
    dispatch(setMessages({ messages: [] }));
  }

  return (
    <Chat
      enableActions
      loading={loading}
      messages={msgs}
      onSubmit={handleChatSubmit}
      onDelete={handleDelete}
      placeholder={msgs.length ? 'Reply...' : 'Query the knowledge base using plain language...'}
      onReset={handleReset}
      selectMultiple={selectMultiple}
      selectable={selectable}
    />
  )
}