import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const tutorSlice = createSlice({
  name: 'tutor',
  initialState: {
    loaded: false,
    loading: false,
    notesLoaded: false,
    notesLoading: false,
    result: null,
    image: null,
    videos: [],
    notes: [],
  },
  reducers: {
    setImage: (state, action) => {
      state.image = action.payload.image;
    },
    setNotes: (state, action) => {
      state.notes = action.payload.notes;
      state.notesLoaded = true;
      state.notesLoading = false;
    },
    setResult: (state, action) => {
      state.result = action.payload.result;
      state.loaded = true;
      state.loading = false;
    },
    setVideos: (state, action) => {
      state.videos = action.payload.videos;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
    startNotesLoad: (state) => {
      state.notesLoaded = false;
      state.notesLoading = true;
    },
  }
});

export const {
  setImage,
  setNotes,
  setResult,
  setVideos,
  startNotesLoad,
  startLoad,
} = tutorSlice.actions;

export const validateTopicAsync = ({ content }) => async (dispatch) => {
  dispatch(startLoad());
  let url = `/api/tutor/validate-topic`;
  const res = await http.post(url, { content });
  dispatch(setResult({ result: res.data.result }));
};

export const findImageAsync = ({ q }) => async (dispatch) => {
  let url = `/api/images/search?q=${q}`;
  const res = await http.get(url);
  const data = res.data;
  const image = data.images[0];
  dispatch(setImage({ image }));
};

export const getNotesAsync = ({ workspaceId, username }) => async (dispatch) => {
  let url = `/api/graph/nodes/Note?workspace_id=${workspaceId}&author=${username}`;
  const res = await http.get(url);
  dispatch(setNotes({ notes: res.data }));
};

export const getVideosAsync = ({ workspaceId }) => async (dispatch) => {
  let url = `/api/graph/nodes/Video?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setVideos({ videos: res.data }));
};

export const saveNoteAsync = ({ node, workspaceId, messages }) => async (dispatch, getState) => {
  dispatch(startNotesLoad());
  let url = `/api/graph/notes`;
  await http.post(url, { node, workspaceId, messages });
  const notes = getState().tutor.notes;
  const { id, name, metadata } = node;
  const props = (metadata || []).reduce((a, m) => {
    a[m.key] = m.value;
    return a;
  }, {});
  const n = {
    ...props,
    id,
    name,
  }
  dispatch(setNotes({ notes: [...notes, n] }));
};

export const deleteNoteAsync = ({ id, workspaceId }) => async (dispatch, getState) => {
  let url = `/api/graph/nodes/Note/${id}?workspace_id=${workspaceId}`;
  const res = await http.delete(url);
  const notes = getState().tutor.notes;
  dispatch(setNotes({ notes: notes.filter(n => n.id !== id) }));
};

export const processSourceContentAsync = ({ action, workspaceId, selectedVideos, ...rest }) => async (dispatch) => {
  dispatch(startLoad());
  let url = `/api/pq/videos/${action}`;
  const res = await http.post(url, {
    params: rest,
    selectedVideos: selectedVideos.slice(0, 3),
    workspaceId,
  });
  dispatch(setResult({ result: res.data }));
};

export const selectLoaded = (state) => state.tutor.loaded;

export const selectLoading = (state) => state.tutor.loading;

export const selectResult = (state) => state.tutor.result;

export const selectImage = (state) => state.tutor.image;

export const selectNotes = (state) => state.tutor.notes;

export const selectVideos = (state) => state.tutor.videos;

export const selectNotesLoaded = (state) => state.tutor.notesLoaded;

export const selectNotesLoading = (state) => state.tutor.notesLoading;

export default tutorSlice.reducer;
