import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EditableTable } from '../../components/EditableTable';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getReviewAsync,
  selectReview,
} from './reviewSlice';

export function Review() {

  const [count, setCount] = useState(2);
  const [dataSource, setDataSource] = useState([]);

  const review = useSelector(selectReview);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Review',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getReviewAsync({ workspaceId, domain: selectedWorkspace.key }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (review?.length > 1) {
      const ds = review.slice(1).map(row => {
        const data = review[0].reduce((a, col, i) => {
          a[col] = row[i];
          return a;
        }, {});
        const idx = review[0].findIndex(col => col === 'id');
        data.key = row[idx];
        return data;
      });
      setDataSource(ds);
    }
  }, [review]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const defaultColumns = useMemo(() => {
    if (review?.length) {
      return review[0].map(dataIndex => ({
        title: dataIndex,
        dataIndex,
        editable: true,
      }));
    }
    return [];
  }, [review]);

  const handleAdd = () => {
    const newData = {};
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  return (
    <div style={{ padding: '16px 0', overflow: 'auto' }}>
      <EditableTable
        dataSource={dataSource}
        defaultColumns={defaultColumns}
        onAdd={handleAdd}
        onDelete={handleDelete}
        onSave={handleSave}
      />
    </div>
  );
}