import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, Upload, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  getOntologiesAsync,
  selectOntologies,
} from '../ontology/ontologiesSlice';

import {
  deleteMetricsAsync,
  fileUploadAsync,
  getMetricsAsync,
  selectLoading,
  selectMetrics,
  selectUploading,
} from './metricsSlice';

export function MetricsList() {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const metrics = useSelector(selectMetrics);
  const ontologies = useSelector(selectOntologies);
  const uploading = useSelector(selectUploading);

  const data = useMemo(() => {
    const list = Object.values(metrics).map(m => ({
      key: m.id,
      name: m.name,
      createdBy: m.createdBy,
      modified: m.modified,
      domain: m.domain,
      entity: m.entity,
      type: m.type,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [metrics]);

  // console.log('data:', data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/metrics/new',
      title: 'Metrics',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getMetricsAsync({ workspaceId }));
      dispatch(getOntologiesAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  const onDelete = () => {
    dispatch(deleteMetricsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onUpload = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      const correlationId = uuidv4();
      dispatch(fileUploadAsync({
        correlationId,
        file: info.file,
        workspaceId: selectedWorkspace.id,
      }));
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { key, name }) => <Link to={`/metrics/${key}`}>{name}</Link>
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      render: (_, { domain }) => Object.values(ontologies).find(o => o.id === domain)?.domain,
    },
    {
      title: 'Entity',
      dataIndex: 'entity',
      render: (_, { domain, entity }) => {
        return Object.values(ontologies)
          .find(o => o.id === domain)?.nodes
          .find(n => n.id === entity)?.data.type;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/metrics/${record.key}`)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Upload
            name="upload"
            showUploadList={false}
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            onChange={onUpload}
          >
            <Button
              type="text" loading={uploading}
            >
              Import
            </Button>
          </Upload>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};

const beforeUpload = (file) => {
  console.log('file:', file);

  const isYAML = file.type === 'application/x-yaml';

  if (!isYAML) {
    message.error('You may only upload a YAML file.');
  }

  const isLt2M = file.size / 1024 / 1024 < 100;

  if (!isLt2M) {
    message.error('File must be smaller than 100MB.');
  }

  return isYAML && isLt2M;
};

// https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 20);
};
