import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';
import isEmpty from 'lodash.isempty';
import { v4 as uuidv4 } from 'uuid';
import useLocalStorageState from 'use-local-storage-state';

import GraphView from '../../components/GraphView';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import { noop } from '../../utils';
import {
  filterGraph,
  getDataSource,
  getDefaultColumns,
  getEdgeMappingColumns,
  getEdges,
  getLegend,
  getSelectedTypes,
} from '../../utils/graphUtils';
import {
  addPropertyTypeAsync,
  getGraphLegendAsync,
  getGraphSizeAsync,
  getGraphAsync,
  getNodesMetadataAsync,
  getNodeOptionsAsync,
  getRelationshipsMetadataAsync,
  runCypherQuery,
  runGraphQuery,
  savePropertyAsync,
  searchGraphAsync,
  selectGraphLegend,
  selectGraphSize,
  selectGraphs,
  selectLoading,
  selectNodesMetadata,
  selectNodeOptions,
  selectRelationshipsMetadata,
} from '../graph/graphSlice';

export function Graph() {

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      hideOnSinglePage: true,
      position: ['topLeft'],
      style: { paddingLeft: 16 },
    },
    filters: {},
  });
  const [tableType, setTableType] = useState('cell-list');
  const [tableView, setTableView] = useState('nodes');
  const [updatedProps, setUpdatedProps] = useLocalStorageState('updated-props');
  const [value, onChange] = useLocalStorageState('graph-value');
  const [pageOptions, setPageOptions] = useState({ pageSize: 1000, current: 1, total: 0 });

  const graphLegend = useSelector(selectGraphLegend);
  const graphSize = useSelector(selectGraphSize);
  const graphs = useSelector(selectGraphs);
  const loading = useSelector(selectLoading);
  const nodesMetadata = useSelector(selectNodesMetadata);
  const relationshipsMetadata = useSelector(selectRelationshipsMetadata);
  const nodeOptions = useSelector(selectNodeOptions);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Explore Knowledge Base',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      const correlationId = uuidv4();
      dispatch(getGraphLegendAsync({ workspaceId }));
      dispatch(getGraphSizeAsync({ workspaceId }));
      if (updatedProps?.queries) {
        dispatch(runGraphQuery({
          correlationId,
          queries: updatedProps.queries,
          workspaceId,
        }));
      } else {
        dispatch(getGraphAsync({ correlationId, workspaceId }));
      }
      setUpdatedProps(cur => ({ ...cur, correlationId }));
      dispatch(getNodesMetadataAsync({ workspaceId }));
      dispatch(getNodeOptionsAsync({ workspaceId }));
      dispatch(getRelationshipsMetadataAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (updatedProps) {
      const { correlationId, selectedTypes, ...props } = updatedProps;
      const graph = graphs[correlationId];
      if (graph) {
        const types = getSelectedTypes(graph, selectedTypes);
        let dataSource, defaultColumns;
        if (tableView === 'nodes') {
          dataSource = getDataSource(graph, tableType === 'adjacency-matrix');
          defaultColumns = getDefaultColumns(graph, tableType === 'adjacency-matrix');
        } else {
          dataSource = getEdges(graph);
          defaultColumns = getEdgeMappingColumns(graph, tableParams);
        }
        onChange({
          ...props,
          dataSource,
          defaultColumns,
          graph: filterGraph(graph, types),
          legend: getLegend({ legend: graphLegend }),
          selectedTypes: types,
        });
      }
    }
  }, [graphs, tableType, tableView]);

  useEffect(() => {
    setPageOptions(cur => ({ ...cur, total: graphSize }));
  }, [graphSize]);

  const hasNewType = useCallback((selectedTypes) => {
    for (const [label, selected] of Object.entries(selectedTypes)) {
      if (selected && !value?.selectedTypes[label]) {
        return true;
      }
    }
    return false;
  }, []);

  const handleAddColumn = (values) => {
    if (values.formType === 'property') {
      const correlationId = uuidv4();
      dispatch(addPropertyTypeAsync({
        ...values.values,
        correlationId,
        workspaceId: selectedWorkspace.id,
        limit: pageOptions.pageSize,
        skip: (pageOptions.current - 1) * pageOptions.pageSize,
        queries: updatedProps.queries,
      }));
      setUpdatedProps(cur => ({ ...cur, correlationId }));
    }
  };

  const handleSaveProperty = ({ record, value }) => {
    const correlationId = uuidv4();
    dispatch(savePropertyAsync({
      nodeType: record.type,
      id: record.id,
      name: value.key,
      value: value.value,
      correlationId,
      workspaceId: selectedWorkspace.id,
      limit: pageOptions.pageSize,
      skip: (pageOptions.current - 1) * pageOptions.pageSize,
      queries: updatedProps.queries,
    }));
    setUpdatedProps(cur => ({ ...cur, correlationId }));
  };

  const handleChange = ({ graph, query, selectedTypes, ...props }) => {
    if ((query || '') !== (value?.query || '')) {
      const correlationId = uuidv4();
      if (query) {
        console.log('dispatch searchGraphAsync [correlationId=%s, query=%s]', correlationId, query);
        dispatch(searchGraphAsync({
          correlationId,
          query,
          workspaceId: selectedWorkspace.id,
        }));
      } else {
        console.log('dispatch getGraphAsync [correlationId=%s]', correlationId);
        dispatch(getGraphAsync({
          correlationId,
          workspaceId: selectedWorkspace.id,
          limit: pageOptions.pageSize,
          skip: (pageOptions.current - 1) * pageOptions.pageSize,
        }));
      }

      // TODO
      setTimeout(() => {
        setUpdatedProps(cur => ({ ...cur, ...props, correlationId, query, selectedTypes }));
      }, 200);

    } else {
      const g = graphs[updatedProps?.correlationId];
      onChange({
        ...props,
        graph: filterGraph(g, selectedTypes),
        query,
        selectedTypes,
      });

      // TODO
      setTimeout(() => {
        setUpdatedProps(cur => ({ ...cur, ...props, query, selectedTypes }));
      }, 200);
    }
  };

  const handlePageChange = (page, pageSize) => {
    const correlationId = uuidv4();
    dispatch(getGraphAsync({
      correlationId,
      workspaceId: selectedWorkspace.id,
      limit: pageSize,
      skip: (page - 1) * pageSize,
    }));
    setUpdatedProps(cur => ({ ...cur, correlationId }));
    setPageOptions(cur => ({ ...cur, pageSize, current: page }));
  };

  const onCypherQuery = (query) => {
    const correlationId = uuidv4();
    dispatch(runCypherQuery({
      correlationId,
      query,
      workspaceId: selectedWorkspace.id,
    }));
    setUpdatedProps(cur => ({ ...cur, correlationId }));
  };

  const onQuery = ({ state, queries, filters, params }) => {
    if (!state) {
      state = updatedProps?.queryBuilderState;
    }
    if (!queries) {
      queries = updatedProps?.queries;
    }
    if (!queries?.length) {
      queries = ['MATCH (n)-[r]-(c) RETURN n, r, c'];
    }
    const correlationId = uuidv4();
    dispatch(runGraphQuery({
      correlationId,
      queries,
      filters,
      params,
      workspaceId: selectedWorkspace.id,
    }));
    setUpdatedProps(cur => ({
      ...cur,
      correlationId,
      queryBuilderState: state,
      queries,
      filters,
      params,
    }));
  };

  if (loading) {
    return (
      <div style={{ marginTop: 40 }}>Loading...</div>
    );
  }
  if (isEmpty(nodesMetadata)) {
    return (
      <div style={{ marginTop: 40 }}>
        <Empty />
      </div>
    );
  }
  return (
    <div style={{ width: '100%', height: 'calc(100% - 24px)', padding: '20px 0' }}>
      <GraphView
        nodesMetadata={nodesMetadata}
        relationshipsMetadata={relationshipsMetadata}
        onChange={handleChange}
        onCypherQuery={onCypherQuery}
        onImageChange={noop}
        onQuery={onQuery}
        onChangeTable={setTableParams}
        tableType={tableType}
        setTableType={setTableType}
        tableView={tableView}
        setTableView={setTableView}
        value={value}
        graphLegend={graphLegend}
        onAddColumn={handleAddColumn}
        onSaveProperty={handleSaveProperty}
        onPageChange={handlePageChange}
        pageOptions={pageOptions}
        queryBuilderState={updatedProps?.queryBuilderState}
        nodeOptions={nodeOptions}
      />
    </div>
  )
}