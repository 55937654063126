import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Space } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import { deleteAllAsync, reindexAsync, selectProcessing } from './adminSlice';

export function Admin() {

  const [correlationIdMap, setCorrelationIdMap] = useState({});

  const processing = useSelector(selectProcessing);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Admin',
    }));
  }, []);

  const handleDeleteAll = () => {
    const correlationId = uuidv4();
    dispatch(deleteAllAsync({
      correlationId,
      workspaceId: selectedWorkspace.id,
    }));
    setCorrelationIdMap(cur => ({ ...cur, deleteAll: correlationId }));
  };

  const handleReindex = () => {
    const correlationId = uuidv4();
    dispatch(reindexAsync({
      correlationId,
      workspaceId: selectedWorkspace.id,
    }));
    setCorrelationIdMap(cur => ({ ...cur, reindex: correlationId }));
  };

  return (
    <div style={{ paddingTop: 40, paddingLeft: 40 }}>
      <Space>
        <Button
          loading={processing[correlationIdMap.deleteAll]}
          onClick={handleDeleteAll}
        >
          Delete All
        </Button>
        <Button
          loading={processing[correlationIdMap.reindex]}
          onClick={handleReindex}
        >
          Reindex
        </Button>
      </Space>
    </div>
  )
}