import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import ReactMarkdown from 'react-markdown';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  getReportAsync,
  selectReport,
} from './reportSlice';

export function Report() {

  const report = useSelector(selectReport);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Management Plan',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getReportAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  return (
    <div className="management-plan" style={{ paddingTop: 40, paddingLeft: 40 }}>
      <Typography.Paragraph>
        The following opportunities have been identified based on
        the structured information provided and information inferred
        from the source documents.
      </Typography.Paragraph>
      <ReactMarkdown>
        {report}
      </ReactMarkdown>
    </div>
  )
}