import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, Upload, message } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import Download from '../../components/Download';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  objectUploadAsync,
  selectUploading,
} from '../uploader/fileUploaderSlice';
import {
  deleteOntologiesAsync,
  getOntologiesAsync,
  selectLoading,
  selectOntologies,
} from './ontologiesSlice';

export function OntologiesList() {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const ontologies = useSelector(selectOntologies);
  const uploading = useSelector(selectUploading);

  const selectedOntologies = selectedRowKeys.map(id => ontologies[id]);

  const data = useMemo(() => {
    const list = Object.values(ontologies).map((o) => ({
      key: o.id,
      domain: o.domain,
      createdBy: o.createdBy,
      modified: o.modified,
    }));
    list.sort((a, b) => a.domain < b.domain ? -1 : 1);
    return list;
  }, [ontologies]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/ontologies/new',
      title: 'Ontologies',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getOntologiesAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  const onDelete = () => {
    dispatch(deleteOntologiesAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onUpload = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      dispatch(objectUploadAsync({
        file: info.file,
        type: 'ontology',
        workspaceId: selectedWorkspace.id,
      }));
    }
  };

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      render: (_, { key, domain }) => <Link to={`/ontologies/${key}`}>{domain}</Link>
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/ontologies/${record.key}`)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginBottom: 16 }}>
        <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
          Delete
        </Button>
        {hasSelected ?
          <>
            <span>Selected {selectedRowKeys.length} items</span>
            <Download filename={'ontologies.json'} payload={selectedOntologies}>
              <Button type="text" icon={<DownloadOutlined />}>
                Export
              </Button>
            </Download>
          </>
          : null
        }
        <Upload
          name="upload"
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={onUpload}
        >
          <Button type="text"
            icon={<UploadOutlined />}
            loading={uploading}
          >
            Import
          </Button>
        </Upload>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ hideOnSinglePage: true }}
        rowSelection={rowSelection}
      />
    </div>
  );
};

const beforeUpload = (file) => {
  // console.log('file:', file);

  const isJSON = file.type === 'application/json';

  if (!isJSON) {
    message.error('You may only upload a JSON file.');
  }

  const isLt2M = file.size / 1024 / 1024 < 100;

  if (!isLt2M) {
    message.error('File must be smaller than 100MB.');
  }

  return isJSON && isLt2M;
};

// https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 20);
};
