import './Tile.css';
import { Link } from 'react-router-dom';
import { Avatar, Space } from 'antd';
import { WifiOutlined } from '@ant-design/icons';
import { DailyVideo, useVideoTrack } from '@daily-co/daily-react';
import { useParticipantProperty } from '@daily-co/daily-react';

import Username from '../Username/Username';

import Me from '../../images/Me1.png';
import PersonaCFO from '../../images/Persona_CFO.png';

export default function Tile({ id, isScreenShare, isLocal, isAlone }) {

  const videoState = useVideoTrack(id);

  let containerCssClasses = isScreenShare ? 'tile-screenshare' : 'tile-video';

  if (isLocal) {
    containerCssClasses += ' self-view';
    if (isAlone) {
      containerCssClasses += ' alone';
    }
  }

  const username = useParticipantProperty(id, 'user_name');

  /* If a participant's video is muted, hide their video and
  add a different background color to their tile. */
  if (videoState.isOff) {
    containerCssClasses += ' no-video';
  }

  return (
    <div className={containerCssClasses}>
      {/* <DailyVideo automirror sessionId={id} type={isScreenShare ? 'screenVideo' : 'video'} /> */}
      {/* {!isScreenShare && <Username id={id} isLocal={isLocal} />} */}
      {!isScreenShare ?
        <Space id="persona" direction="vertical">
          {isLocal ?
            <Space>
              <Avatar size={128} src={Me} />
              <Link
                className="active"
                style={{ whiteSpace: 'nowrap' }}
              >
                {username} (you)
              </Link>
              {/* <WifiOutlined
                rotate={90}
                size="large"
                style={{ fontSize: '48px', color: 'rgb(188, 210, 232)' }}
              /> */}
            </Space>
            :
            <Space>
              <Avatar size={128} src={PersonaCFO} />
              <Link
                className="active"
              >
                Chief Financial Officer
              </Link>
            </Space>
          }
        </Space>
        : null
      }
    </div>
  );
}
