import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  deleteMetricSetsAsync,
  getMetricSetsAsync,
  materializeMetricSetAsync,
  selectCorrelationId,
  selectLoading,
  selectMetricSets,
} from './metricSetsSlice';

export function MetricSetsList() {

  const [correlationId, setCorrelationId] = useState(null);
  const [onDone, setOnDone] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const loading = useSelector(selectLoading);
  const metricSets = useSelector(selectMetricSets);
  const returnCorrelationId = useSelector(selectCorrelationId);

  const data = useMemo(() => {
    const list = Object.values(metricSets).map(s => ({
      key: s.id,
      name: s.name,
      createdBy: s.createdBy,
      modified: s.modified,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [metricSets]);

  // console.log('data:', data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/metric-sets/new',
      title: 'Metric Sets',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getMetricSetsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (correlationId && correlationId === returnCorrelationId && onDone) {
      onDone();
      setCorrelationId(null);
      setOnDone(null);
    }
  }, [returnCorrelationId]);

  const handleMaterialize = (id) => {
    const correlationId = uuidv4();
    dispatch(materializeMetricSetAsync({
      correlationId,
      id,
      workspaceId: selectedWorkspace.id,
    }));
  };

  const onDelete = () => {
    dispatch(deleteMetricSetsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { key, name }) => <Link to={`/metric-sets/${key}`}>{name}</Link>
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => navigate(`/metric-sets/${record.key}`)}
          >
            Edit
          </Button>
          <Button type="link"
            style={{ paddingLeft: 0 }}
            onClick={() => handleMaterialize(record.key)}
          >
            Materialize
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
