export const getBaseURL = (endpoint) => {
  const url = new URL(endpoint);
  return `${url.protocol}//${url.hostname}`;
};

export const getExtension = (filepath) => {
  if (!filepath) return null;
  const index = filepath.lastIndexOf('.');
  return filepath.slice(index + 1);
};
