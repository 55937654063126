export const formatNumber = (num, precision = 0) => {
  if (num) {
    num = +num;
    if (!isNaN(num)) {
      num = Math.round((num + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision);
      return num.toLocaleString('en-US');
    }
  }
  return null;
}

export const formatPercentage = (number) => {
  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  return numberFormatter.format(number);
};

export const isFloat = (n) => {
  return !isNaN(n) && Number(n) % 1 !== 0;
};

export const isInt = (n) => {
  return !isNaN(n) && Number(n) % 1 === 0;
};

export const toNumber = (str, defaultValue) => {
  if (typeof str === 'undefined' || str === null) {
    return defaultValue;
  }
  try {
    if (str.indexOf('.') !== -1) {
      return parseFloat(str, defaultValue);
    }
    return parseInt(str, defaultValue);
  } catch (e) {
    return defaultValue;
  }
};
