// tools.js
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
// import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';
import AttachesTool from '@editorjs/attaches';
import Autocomplete from './Autocomplete';
import ShortenInlineTool from './ShortenInlineTool';
import ExpandInlineTool from './ExpandInlineTool';
import RephraseInlineTool from './RephraseInlineTool';

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  autocomplete: {
    class: Autocomplete,
  },
  embed: Embed,
  table: Table,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: Image,
  raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  attaches: {
    class: AttachesTool,
    config: {
      endpoint: 'https://memex.ngrok.io/api/upload'
    }
  },
  shorten: {
    class: ShortenInlineTool,
    inlineToolbar: true,
  },
  expand: {
    class: ExpandInlineTool,
    inlineToolbar: true,
  },
  rephrase: {
    class: RephraseInlineTool,
    inlineToolbar: true,
  },
};