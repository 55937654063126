import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    processed: {},
    processing: {},
  },
  reducers: {
    startProcess: (state, action) => {
      const { correlationId } = action.payload;
      delete state.processed[correlationId];
      state.processing[correlationId] = true;
    },
    endProcess: (state, action) => {
      const { correlationId } = action.payload;
      state.processed[correlationId] = true;
      delete state.processing[correlationId];
    },
  }
});

export const {
  startProcess,
  endProcess,
} = adminSlice.actions;

export const deleteAllAsync = ({ correlationId, workspaceId }) => async (dispatch) => {
  dispatch(startProcess({ correlationId }));
  const url = `/api/admin?workspace_id=${workspaceId}`;
  const res = await http.delete(url);
  dispatch(endProcess({ correlationId }));
};

export const reindexAsync = ({ correlationId, workspaceId }) => async (dispatch) => {
  dispatch(startProcess({ correlationId }));
  const url = `/api/admin/reindex?workspace_id=${workspaceId}`;
  const res = await http.post(url);
  dispatch(endProcess({ correlationId }));
};

export const selectProcessed = (state) => state.admin.processed;

export const selectProcessing = (state) => state.admin.processing;

export default adminSlice.reducer;
