import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const artefactsSlice = createSlice({
  name: 'artefacts',
  initialState: {
    artefacts: {},
    correlationId: null,
    loaded: false,
    loading: false,
  },
  reducers: {
    removeArtefacts: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.artefacts[id];
      }
    },
    resetArtefacts: (state) => {
      state.artefacts = {};
    },
    setCorrelationId: (state, action) => {
      state.correlationId = action.payload.correlationId;
    },
    setArtefacts: (state, action) => {
      for (const artefact of action.payload.artefacts) {
        state.artefacts[artefact.id] = artefact;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeArtefacts,
  resetArtefacts,
  setCorrelationId,
  setArtefacts,
  startLoad,
} = artefactsSlice.actions;

export const getArtefactsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/artefacts?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setArtefacts({ artefacts: res.data }));
};

export const getArtefactAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/artefacts/${id}`;
  const res = await http.get(url);
  dispatch(setArtefacts({ artefacts: [res.data] }));
};

export const createArtefactAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/artefacts';
  const res = await http.post(url, values);
  dispatch(setArtefacts({ artefacts: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const updateArtefactAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/artefacts/${id}`;
  const res = await http.put(url, values);
  dispatch(setArtefacts({ artefacts: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const deleteArtefactsAsync = (ids) => async (dispatch) => {
  const url = `/api/artefacts?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeArtefacts({ ids }));
};

export const selectArtefacts = (state) => state.artefacts.artefacts;

export const selectCorrelationId = (state) => state.artefacts.correlationId;

export const selectLoaded = (state) => state.artefacts.loaded;

export const selectLoading = (state) => state.artefacts.loading;

export default artefactsSlice.reducer;
