// https://github.com/ant-design/ant-design/issues/9459
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const TagItem = ({ label, originLabel, dataHandlerId, onMouseDown, onClose, style }, ref) => (
  <span
    className="ant-select-selection-item"
    title={originLabel}
    style={style}
    ref={ref}
    onMouseDown={onMouseDown}
    data-handler-id={dataHandlerId}
  >
    <span className="ant-select-selection-item-content">{label}</span>
    <span className="ant-select-selection-item-remove" unselectable="on" aria-hidden="true">
      <CloseOutlined onClick={onClose} />
    </span>
  </span>
);

export default React.forwardRef(TagItem);