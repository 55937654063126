import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Layout,
  Select,
  Space,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import ReactFlow, { addEdge, useNodesState, useEdgesState, MarkerType } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash.clonedeep';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import CustomNode from './CustomNode';
import FloatingEdge from './FloatingEdge';
import CustomConnectionLine from './CustomConnectionLine';
import {
  createOntologyAsync,
  getOntologyAsync,
  selectLoaded,
  selectOntologies,
  updateOntologyAsync,
} from './ontologiesSlice';

import 'reactflow/dist/style.css';
import './style.css';

const { TextArea } = Input;
const { Content, Sider } = Layout;

const subFieldLayout = {
  colon: false,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const connectionLineStyle = {
  strokeWidth: 1,
  stroke: 'black',
};

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const defaultEdgeOptions = {
  style: { strokeWidth: 1, stroke: 'black' },
  type: 'floating',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    color: 'black',
  },
};

export function OntologyEditor() {

  const [selectedSchemaEdge, setSelectedSchemaEdge] = useState(null);
  const [selectedSchemaNode, setSelectedSchemaNode] = useState(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const loaded = useSelector(selectLoaded);
  const ontologies = useSelector(selectOntologies);

  // console.log('ontologies:', ontologies);

  const [nodeForm] = Form.useForm();

  const propsValue = Form.useWatch('properties', nodeForm);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const id = location.pathname.match(/\/ontologies\/(.*)/)[1];
  const isNew = id === 'new';
  const ontology = ontologies[id];

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Ontology Editor',
    }));
    if (!isNew) {
      dispatch(getOntologyAsync(id));
    }
  }, []);

  const deserializeData = (obj) => {
    const clone = cloneDeep(obj);
    const { data, ...rest } = clone;
    if (data.synonyms) {
      data.synonyms = data.synonyms.join(', ');
      if (!data.synonyms.length) {
        data.synonyms = undefined;
      }
    } else {
      data.synonyms = undefined;
    }
    if (data.properties) {
      const properties = [];
      for (let prop of data.properties) {
        if (prop.dataType === 'enum' || prop.dataType === 'list_enum') {
          if (prop.enum) {
            prop.enum = prop.enum.join(', ');
          } else {
            prop.enum = undefined;
          }
        }
        properties.push(prop);
      }
      if (properties.length) {
        data.properties = properties;
      } else {
        data.properties = undefined;
      }
    } else {
      data.properties = undefined;
    }
    return { ...rest, data };
  };

  useEffect(() => {
    if (loaded && ontology) {
      setNodes((ontology.nodes || []).map(deserializeData));
      const edges = (ontology.edges || [])
        .map(e => ({ ...e, data: { ...e.data, label: e.data?.type } }));
      setEdges(edges);
    }
  }, [loaded]);

  const addNode = () => {
    setNodes(cur => [...cur, {
      id: uuidv4(),
      type: 'custom',
      position: { x: 0, y: 0 },
    }]);
  };

  const onCancel = () => {
    navigate('/ontologies');
  };

  const serializeData = (obj) => {
    const clone = cloneDeep(obj);
    const { data, ...rest } = clone;
    if (data.synonyms) {
      data.synonyms = data.synonyms.trim().split(/\s*,\s*/);
      if (!data.synonyms.length) {
        data.synonyms = undefined;
      }
    } else {
      data.synonyms = undefined;
    }
    if (data.properties) {
      const properties = [];
      for (let prop of data.properties) {
        if (prop.dataType === 'enum' || prop.dataType === 'list_enum') {
          if (prop.enum) {
            prop.enum = prop.enum.trim().split(/\s*,\s*/);
          } else {
            prop.enum = undefined;
          }
        }
        properties.push(prop);
      }
      if (properties.length) {
        data.properties = properties;
      } else {
        data.properties = undefined;
      }
    } else {
      data.properties = undefined;
    }
    return { ...rest, data };
  };

  const onFinish = (values) => {
    const ns = nodes.map(serializeData);
    const es = [];
    for (const edge of edges) {
      if (
        edge.data &&
        Object.keys(edge.data).length &&
        ns.find(n => n.id === edge.source) &&
        ns.find(n => n.id === edge.target)
      ) {
        es.push(edge);
      }
    }
    if (isNew) {
      dispatch(createOntologyAsync({
        values: {
          ...values,
          nodes: ns,
          edges: es,
          workspaceId: selectedWorkspace.id,
        },
      }));
    } else {
      dispatch(updateOntologyAsync({
        id,
        values: {
          ...values,
          nodes: ns,
          edges: es,
        },
      }));
    }
    navigate('/ontologies');
  };

  const handleSchemaNodeClick = (ev, node) => {
    nodeForm.resetFields();
    nodeForm.setFieldsValue(node.data);
    setSelectedSchemaNode(node);
  };

  const handleSchemaEdgeClick = (ev, edge) => {
    nodeForm.resetFields();
    nodeForm.setFieldsValue(edge.data);
    setSelectedSchemaEdge(edge);
  }

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const onSchemaFormCancel = () => {
    nodeForm.resetFields();
    setSelectedSchemaEdge(null);
    setSelectedSchemaNode(null);
  };

  const onSchemaFormFinish = (values) => {
    if (selectedSchemaEdge) {
      const newEdge = {
        ...selectedSchemaEdge,
        data: {
          ...selectedSchemaEdge.data,
          ...values,
        }
      };
      const index = edges.findIndex(e => e.id === selectedSchemaEdge.id);
      const newEdges = [...edges];
      newEdges.splice(index, 1, newEdge);
      setEdges(newEdges);
    } else {
      const newNode = {
        ...selectedSchemaNode,
        data: {
          ...selectedSchemaNode.data,
          ...values,
        }
      };
      const index = nodes.findIndex(nd => nd.id === selectedSchemaNode.id);
      const newNodes = [...nodes];
      newNodes.splice(index, 1, newNode);
      setNodes(newNodes);
    }
    nodeForm.resetFields();
    setSelectedSchemaEdge(null);
    setSelectedSchemaNode(null);
  };

  if (!isNew && !loaded) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <div style={{ height: '100%', margin: '0 -16px' }}>
      <Layout style={{ width: '100%', height: '100%' }}>
        <Content style={{ display: 'flex', flexDirection: 'column' }}>
          <Form
            onFinish={onFinish}
            initialValues={ontology}
          >
            <Form.Item
              label="Domain"
              name="domain"
              rules={[
                {
                  required: true,
                  message: 'Please enter the domain',
                },
              ]}
              style={{ width: 450 }}
            >
              <Input />
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space>
                <Button type="default" onClick={onCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">Save</Button>
              </Space>
            </Form.Item>
          </Form>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button className="add-btn"
              type="text"
              size="large"
              icon={<PlusOutlined />}
              onClick={addNode}
            />
          </div>
          <div style={{ flex: 1, position: 'relative' }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              fitView
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              defaultEdgeOptions={defaultEdgeOptions}
              connectionLineComponent={CustomConnectionLine}
              connectionLineStyle={connectionLineStyle}
              onNodeClick={handleSchemaNodeClick}
              onEdgeClick={handleSchemaEdgeClick}
            />
          </div>
        </Content>
        <Sider
          theme="light"
          width={400}
          style={{ padding: '10px 15px' }}
        >
          {selectedSchemaEdge || selectedSchemaNode ?
            <Form
              autoComplete="off"
              form={nodeForm}
              layout="vertical"
              onFinish={onSchemaFormFinish}
            >
              <div
                style={{ fontWeight: 600, marginBottom: 8 }}
              >
                {selectedSchemaEdge ? 'Edge' : 'Node'}
              </div>
              <Form.Item
                label="Type"
                name="type"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
              >
                <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
              </Form.Item>
              <Form.Item
                label="Synonyms"
                name="synonyms"
              >
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 14 }}
                  placeholder="Enter comma-separated list"
                />
              </Form.Item>
              <div
                style={{ fontWeight: 600, marginBottom: 8 }}
              >
                Properties
              </div>
              <Form.Item>
                <Form.List name="properties">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item key={field.name}
                        >
                          <Form.Item
                            {...subFieldLayout}
                            label={index === 0 ? 'Property' : ''}
                            name={[field.name, 'property']}
                            style={{ display: 'inline-block', width: 'calc(50% - 20px)', marginBottom: 0 }}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            {...subFieldLayout}
                            label={index === 0 ? 'Data Type' : ''}
                            name={[field.name, 'dataType']}
                            style={{ display: 'inline-block', width: 'calc(50% - 20px)', marginBottom: 0, marginLeft: 8 }}
                          >
                            <Select
                              allowClear
                              options={[
                                {
                                  label: 'Tag',
                                  value: 'tag',
                                },
                                {
                                  label: 'String',
                                  value: 'string',
                                },
                                {
                                  label: 'String List',
                                  value: 'string_list',
                                },
                                {
                                  label: 'String Enum',
                                  value: 'enum',
                                },
                                {
                                  label: 'String List Enum',
                                  value: 'list_enum',
                                },
                                {
                                  label: 'Integer',
                                  value: 'int',
                                },
                                {
                                  label: 'Float',
                                  value: 'float',
                                },
                                {
                                  label: 'Boolean',
                                  value: 'boolean',
                                },
                              ]}
                            />
                          </Form.Item>
                          {propsValue?.[index]?.dataType === 'enum' || propsValue?.[index]?.dataType === 'list_enum' ?
                            <Form.Item
                              name={[field.name, 'enum']}
                              style={{ display: 'inline-block', marginBottom: 0, marginTop: 8, width: 'calc(100% - 32px)' }}
                            >
                              <TextArea
                                autoSize={{ minRows: 1, maxRows: 14 }}
                                placeholder="Enter comma-separated list"
                              />
                            </Form.Item>
                            : null
                          }
                          <Form.Item
                            {...subFieldLayout}
                            label={index === 0 ? ' ' : ''}
                            style={{
                              display: 'inline-block',
                              width: '32px',
                              marginBottom: 0,
                              marginTop: propsValue?.[index]?.dataType === 'enum' ? (index === 0 ? -62 : -32) : 0,
                            }}
                          >
                            <Button type="text"
                              icon={<CloseOutlined />}
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          </Form.Item>
                        </Form.Item>
                      ))}
                      <Form.Item
                        style={{ marginBottom: 0 }}
                      >
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: '100%', zIndex: 101 }}
                          icon={<PlusOutlined />}
                        >
                          Add Property
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Form.Item>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 8 }}>
                  <Button type="primary" htmlType="submit">Save</Button>
                  <Button type="default" onClick={onSchemaFormCancel}>Cancel</Button>
                </div>
              </Form.Item>
            </Form>
            : null
          }
        </Sider>
      </Layout>
    </div>
  );
}