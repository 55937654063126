// Renders a date in the local timezone, including day of the week.
// e.g. 'Fri, 22 May 2020'
const dateFormatter = new Intl.DateTimeFormat(
  [], { 'year': 'numeric', 'month': 'long', 'day': 'numeric', 'weekday': 'short' }
)

// Renders an HH:MM time in the local timezone, including timezone info.
// e.g. '12:17 BST'
const timeFormatter = new Intl.DateTimeFormat(
  [], { 'hour': 'numeric', 'minute': 'numeric', 'timeZoneName': 'short' }
)

// Given an ISO 8601 date string, render it as a more friendly date
// in the user's timezone.
//
// Examples:
// - 'today @ 12:00 BST'
// - 'yesterday @ 11:00 CST'
// - 'Fri, 22 May 2020 @ 10:00 PST'
//
export const getHumanFriendlyDateString = (iso8601_date_string) => {
  const date = new Date(Date.parse(iso8601_date_string));

  // When are today and yesterday?
  const today = new Date();
  const yesterday = new Date().setDate(today.getDate() - 1);

  // We have to compare the *formatted* dates rather than the actual dates --
  // for example, if the UTC date and the localised date fall on either side
  // of midnight.
  // eslint-disable-next-line
  if (dateFormatter.format(date) == dateFormatter.format(today)) {
    return 'today @ ' + timeFormatter.format(date);
    // eslint-disable-next-line
  } else if (dateFormatter.format(date) == dateFormatter.format(yesterday)) {
    return 'yesterday @ ' + timeFormatter.format(date);
  } else {
    return dateFormatter.format(date) + ' @ ' + timeFormatter.format(date);
  }
};

// Given an ISO 8601 date string, render a human-friendly description
// of how long ago it was, if recent.
//
// Examples:
// - 'just now'
// - '10 seconds ago'
// - '20 minutes ago'
//
export const getHumanFriendlyDeltaString = (iso8601_date_string) => {
  const date = new Date(Date.parse(iso8601_date_string));
  const now = new Date();

  const deltaMilliseconds = now - date;
  const deltaSeconds = Math.floor(deltaMilliseconds / 1000);
  const deltaMinutes = Math.floor(deltaSeconds / 60);
  const deltaHours = Math.floor(deltaMinutes / 60);
  const deltaDays = Math.floor(deltaHours / 24);

  if (deltaSeconds < 5) {
    return 'just now';
  } else if (deltaSeconds < 60) {
    return deltaSeconds + ' seconds ago';
    // eslint-disable-next-line
  } else if (deltaMinutes == 1) {
    return '1 minute ago';
  } else if (deltaMinutes < 60) {
    return deltaMinutes + ' minutes ago';
    // eslint-disable-next-line
  } else if (deltaHours == 1) {
    return '1 hour ago';
  } else if (deltaHours < 24) {
    return deltaHours + ' hours ago';
  } else {
    return deltaDays + ' days ago';
  }
};
