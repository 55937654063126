import React from 'react';
import {
  NavLink,
} from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  AimOutlined,
  AlertOutlined,
  ApartmentOutlined,
  AppstoreAddOutlined,
  AppstoreOutlined,
  CommentOutlined,
  ControlOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  FileOutlined,
  FormOutlined,
  FundOutlined,
  HomeOutlined,
  PaperClipOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  SettingOutlined,
  ShareAltOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import useLocalStorageState from 'use-local-storage-state';

import MyLogo from '../images/compliance_copilot_logo10.png';
import MyLogoFull from '../images/compliance_copilot_logo9.png';
import PQLogo from '../images/pq_logo.svg';

const APP = process.env.REACT_APP_NAME === 'Compliance Copilot' ? 'compliancecopilot' : 'pqacademy';

const { Sider } = Layout;

function SideMenu({ isDarkMode, isAdmin, currentUser }) {

  const [collapsed, setCollapsed] = useLocalStorageState('side-menu', { defaultValue: true });

  return (
    <Sider id="menu"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      theme={isDarkMode ? 'dark' : 'light'}
      width={235}
    >
      <NavLink to="/" className={'logo-image' + (collapsed ? ' collapsed' : '')}>
        <div>
          <div>
            {APP === 'pqacademy' ? (
              collapsed ?
                <img
                  src={PQLogo}
                  style={{ width: 45, height: 27 }}
                  alt={process.env.REACT_APP_NAME} />
                :
                <img
                  src={'https://cdn.prod.website-files.com/65a0ee83191f426bb713550e/65a0ee83191f426bb71355b7_logotype%20-%20white.svg'}
                  alt={process.env.REACT_APP_NAME} />
            ) : (
              collapsed ?
                <img
                  src={MyLogo}
                  style={{ width: 45, height: 45 }}
                  alt={process.env.REACT_APP_NAME} />
                : <img
                  src={MyLogoFull}
                  alt={process.env.REACT_APP_NAME} />
            )}
          </div>
        </div>
      </NavLink>
      <br />
      <Menu
        items={getSideMenuItems(currentUser, isAdmin)}
        mode="vertical"
        theme={isDarkMode ? 'dark' : 'light'}
        triggerSubMenuAction="click"
      />
    </Sider>
  );
}

const getSideMenuItems = (currentUser, isAdmin) => {
  let sideMenuItems = [];

  if (isAdmin) {
    sideMenuItems.push(...[
      {
        key: 'home',
        icon: <HomeOutlined />,
        label: (
          <NavLink to="/">Home</NavLink>
        ),
      },
      {
        key: 'workspace',
        icon: <TeamOutlined />,
        label: (
          <NavLink to="/workspaces">Workspaces</NavLink>
        ),
      },
      {
        key: 'context',
        icon: <FileOutlined />,
        label: "Acquisition",
        children: [
          {
            key: 'documents',
            icon: <FileOutlined />,
            label: (
              <NavLink to="/uploads">Data Sources</NavLink>
            ),
          },
        ],
      },
      {
        key: 'inference',
        icon: <AppstoreAddOutlined />,
        label: (
          <NavLink to="/inference">Augmentation</NavLink>
        ),
      },
      {
        key: 'investigation-parent',
        icon: <AimOutlined />,
        label: "Analysis",
        children: [
          {
            key: 'investigation',
            icon: <AimOutlined />,
            label: 'Analysis',
            children: [
              {
                key: 'graph',
                icon: <ShareAltOutlined />,
                label: (
                  <NavLink to="/graph">Explore</NavLink>
                ),
              },
              {
                key: 'chat',
                icon: <CommentOutlined />,
                label: (
                  <NavLink to="/chat">Query</NavLink>
                ),
              },
              {
                key: 'search',
                icon: <SearchOutlined />,
                label: (
                  <NavLink to="/search">Search</NavLink>
                ),
              },
            ],
          },
          {
            key: 'notebooks',
            icon: <PaperClipOutlined />,
            label: (
              <NavLink to="/notebooks">Casebooks</NavLink>
            ),
          },
        ],
      },
      // {
      //   key: 'review',
      //   icon: <SafetyCertificateOutlined />,
      //   label: (
      //     <NavLink to="/review">Review</NavLink>
      //   ),
      // },
      {
        key: 'documentation',
        icon: <FormOutlined />,
        label: "Artefacts",
        children: [
          {
            key: 'artefacts-parent',
            icon: <FormOutlined />,
            label: 'Artefacts',
            children: [
              {
                key: 'artefacts',
                icon: <FormOutlined />,
                label: (
                  <NavLink to="/artefacts">Artefacts</NavLink>
                ),
              },
              {
                key: 'sections',
                icon: <ApartmentOutlined />,
                label: (
                  <NavLink to="/sections">Sections</NavLink>
                ),
              },
            ]
          },
          {
            key: 'metrics-parent',
            icon: <FundOutlined />,
            label: 'Metrics',
            children: [
              {
                key: 'metrics',
                icon: <FundOutlined />,
                label: (
                  <NavLink to="/metrics">Metrics</NavLink>
                ),
              },
              {
                key: 'metric-sets',
                icon: <AppstoreOutlined />,
                label: (
                  <NavLink to="/metric-sets">Metric Sets</NavLink>
                ),
              },
            ]
          },
        ],
      },
      // {
      //   key: 'report',
      //   icon: <AlertOutlined />,
      //   label: (
      //     <NavLink to="/report">Report</NavLink>
      //   ),
      // },
      // {
      //   key: 'interview',
      //   icon: <UserOutlined />,
      //   label: (
      //     <NavLink to="/interviews">Interview</NavLink>
      //   ),
      // },
      {
        key: 'admin-parent',
        icon: <ControlOutlined />,
        label: 'Admin',
        children: [
          {
            key: 'users',
            icon: <UserOutlined />,
            label: (
              <NavLink to="/users">Users</NavLink>
            ),
          },
          {
            key: 'ontology',
            icon: <DeploymentUnitOutlined />,
            label: (
              <NavLink to="/ontologies">Ontologies</NavLink>
            ),
          },
          {
            key: 'settings',
            icon: <SettingOutlined />,
            label: (
              <NavLink to="/settings">Settings</NavLink>
            ),
          },
          {
            key: 'admin',
            icon: <DesktopOutlined />,
            label: (
              <NavLink to="/admin">Admin</NavLink>
            ),
          },
        ]
      },
    ]);
  }
  sideMenuItems.push(
    {
      key: 'tutor',
      icon: <SolutionOutlined />,
      label: (
        <NavLink to="/tutor">Academy</NavLink>
      ),
    },
  );

  return sideMenuItems;
};

export default SideMenu;
