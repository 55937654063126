import isObject from 'lodash.isobject';

const COLORS = {
  light: ['#87d068', '#2db7f5', '#ca3dd4', '#f56a00', '#7265e6', '#ffbf00', '#00a2ae'],
  dark: ['rgba(255,255,255,0.2)', 'rgba(255,255,255,0.4)'],
};

export const getColor = (key, isDarkMode) => {
  if (!key) return 'rgba(0, 0, 0, 0.25)';
  if (isDarkMode) {
    return COLORS.dark[hashIndex(key, COLORS.dark.length)];
  }
  return COLORS.light[hashIndex(key, COLORS.light.length)];
};

export const hashIndex = (key, n) => {
  let hash = 0;
  for (let i = 0; i < key.length; i++) {
    hash += key.charCodeAt(i);
  }
  return hash % n;
};

export const isQuasiArray = (obj) => {
  if (!isObject(obj)) return false;
  const keys = Object.keys(obj);
  keys.sort();
  // use `==` to compare numbers as strings
  return keys.every((k, i) => k == i);
};

export const noop = () => { };

export const notEmpty = (val) => {
  return typeof val === 'object' && val !== null && Object.keys(val).length;
};

export const pollStatus = (
  http,
  correlationId,
  onDone,
  pollingInterval = 5000,
  timeout = 120000,
) => {
  const start = new Date();
  const intervalId = setInterval(async () => {
    let res;
    try {
      res = await http.get('/api/status/' + correlationId);
      clearInterval(intervalId);
      onDone();
    } catch (err) {
      // 423 - locked ~ not ready
      if (err.response?.status !== 423) {
        clearInterval(intervalId);
        onDone();
      } else {
        const now = new Date();
        const diff = now - start;
        if (diff > timeout) {
          clearInterval(intervalId);
        }
      }
    }
  }, pollingInterval);
  return intervalId;
};
