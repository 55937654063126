import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Breadcrumb,
  Button,
  Carousel,
  Checkbox,
  Collapse,
  Divider,
  Image,
  Mentions,
  Popover,
  Radio,
  Space,
  Spin,
  Tag,
  Typography,
} from 'antd';
import {
  BulbOutlined,
  CloseOutlined,
  LeftOutlined,
  ProfileOutlined,
  ReadOutlined,
  SendOutlined,
  ShrinkOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import YouTube from 'react-youtube';
import { FlashcardArray } from 'react-quizlet-flashcard';
import Markdown from 'react-markdown';
import {
  CircleMenu,
  CircleMenuItem,
  TooltipPlacement,
} from 'react-circular-menu';

import {
  selectQuizLoading,
} from '../features/chat/chatSlice';

import { jsonToPlainText } from '../jsonToPlainText';
import { formatNumber } from '../numberUtils';

const opts = {
  height: '180',
  width: '320',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
    rel: 0,
  },
};

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

function Ellipsis({ text, len }) {
  if (text?.length > len) {
    text = text.substr(0, len) + '...';
  }
  return (
    <span>{text}</span>
  );
}

export function Chat({
  acceptFreeTextInput,
  disabled,
  enableActions,
  enableCritique,
  loading,
  messages,
  onCritique,
  onDelete,
  onGetQuiz,
  onSave,
  onSelected,
  onSubmit,
  onReset,
  onShowCitation,
  placeholder,
  score,
  selectable,
  selectMultiple,
  suggestPrompts,
  tourRefs,
  traceId,
  user,
  image,
  onCreateFlashCards,
  onCreateMashup,
  onCreateOutline,
  onCreateStudyNote,
  onCreateSummary,
  onShowPeople,
  onShowPodcasts,
  onCreateNote,
  isAdmin,
  breadcrumbs,
  firstSession,
  skip,
  setSkip,
  speakerSkip,
  setSpeakerSkip,
}) {

  if (!placeholder) {
    placeholder = messages.length ? 'Question...' : 'What can we help you with today...';
  }

  const [activeKey, setActiveKey] = useState([]);
  const [elaboratedKeys, setElaboratedKeys] = useState({});
  const [indeterminate, setIndeterminate] = useState(false);
  const [selected, setSelected] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [input, setInput] = useState(null);
  const [player, setPlayer] = useState(null);
  const [videoParams, setVideoParams] = useState({});
  const [hitOpen, setHitOpen] = useState({});
  const [menuOpen, setMenuOpen] = useState({});
  const [selectedMenuOption, setSelectedMenuOption] = useState(null);

  const quizLoading = useSelector(selectQuizLoading);

  const latestResponseRef = useRef(null);
  const messageScrollerRef = useRef(null);
  const scrollTopRef = useRef(null);

  let previewVisible = false;

  const selectedEntries = Object.entries(selected).filter(([_, v]) => v.checked).map(([k, v]) => [k, v.index]);
  selectedEntries.sort((a, b) => a[1] < b[1] ? -1 : 1);
  const selectedKeys = selectedEntries.map(x => x[0]);
  const hasSelected = selectedKeys.length > 0;

  const hasMessages = messages.length > 0;

  const navigate = useNavigate();

  const findMessage = (key) => {
    for (const m of messages) {
      if (m.role === 'assistant' && Array.isArray(m.content)) {
        const content = m.content.find(c => c.key === key);
        if (content) {
          return { message: m, content };
        }
      } else if (m.key === key) {
        return { message: m };
      }
    }
    return {};
  };

  const handleChange = (key) => {
    if (previewVisible) return;
    scrollTopRef.current = messageScrollerRef.current.scrollTop;
    if (selectMultiple) {
      const { message, content } = findMessage(key);
      let selectedUpdate;
      if (content) {
        selectedUpdate = {
          ...selected,
          ...message.content.reduce((a, c) => {
            a[c.key] = {
              checked: false,
              index: message.index,
            };
            return a;
          }, {}),
          [key]: {
            checked: !selected[key]?.checked,
            index: message.index,
          },
        };
      } else {
        selectedUpdate = {
          ...selected,
          [key]: {
            checked: !selected[key]?.checked,
            index: message.index,
          },
        };
      }
      const selectedEntries = Object.entries(selectedUpdate).filter(([_, v]) => v.checked).map(([k, v]) => [k, v.index]);
      selectedEntries.sort((a, b) => a[1] < b[1] ? -1 : 1);
      const selectedKeys = selectedEntries.map(x => x[0]);
      if (selectedKeys.length > 0 && selectedKeys.length < messages.length) {
        setIndeterminate(true);
      } else {
        setIndeterminate(false);
      }
      if (selectedKeys.length === messages.length) {
        setCheckAll(true);
      } else {
        setCheckAll(false);
      }
      setSelected(selectedUpdate);
      if (typeof onSelected === 'function') {
        onSelected(selectedKeys);
      }
    } else {
      setSelected({ [key]: true });
      if (typeof onSelected === 'function') {
        onSelected([key]);
      }
    }
    setTimeout(() => {
      scrollTopRef.current = null;
    }, 2000);
  };

  const handleReset = () => {
    setSelected({});
    setSelectedMenuOption(null);
    if (typeof onReset === 'function') {
      onReset();
    }
  };

  const handleSave = () => {
    setSelected({});
    if (typeof onSave === 'function') {
      onSave(messages);
    }
  };

  const handleCreateNote = () => {
    if (typeof onCreateNote === 'function') {
      const key = selectedKeys[0];
      const m = messages.find(m => m.key === key || (m.role === 'assistant' && m.content.find(c => c.key === key)));
      const contents = [];
      if (m) {
        const c = m.content;
        if (typeof c === 'string') {
          contents.push(c);
        } else if (Array.isArray(c)) {
          if (c[0].content) {
            contents.push(c[0].content);
          }
          if (c[0].elaboration) {
            contents.push(c[0].elaboration);
          }
        }
      }
      // const msgs = selectedKeys.map(key => messages.find(m => m.key === key || (m.role === 'assistant' && m.content.find(c => c.key === key))));
      if (contents.length) {
        const name = breadcrumbs.map(c => c.title).join(' / ') + ' - ' + new Date().toLocaleString();
        onCreateNote({
          name,
          text: contents.join('\n\n'),
          // messages: msgs,
        });
      }
    }
    setCheckAll(false);
    setSelected({});
    setIndeterminate(false);
  };

  const handleSubmit = () => {
    const msg = {
      key: uuidv4(),
      role: 'user',
      content: input,
    };
    onSubmit({ messages: [...messages, msg] });
    setSelectedMenuOption(null);
  };

  const handleSuggestedQuery = (query, hidden) => {
    setInput(query);
    const currentMessage = messages[messages.length - 1];
    const content = currentMessage.content[0];
    const queries = content.suggested_next_queries;
    const index = queries.findIndex(q => q.query === query);
    const suggested_next_queries = [
      ...queries.slice(0, index),
      { ...queries[index], selected: true },
      ...queries.slice(index + 1),
    ];
    setTimeout(() => {
      const newMessage = {
        key: uuidv4(),
        role: 'user',
        content: query,
        hidden,
      };
      onSubmit({
        messages: [
          ...messages.slice(0, -1),
          { ...currentMessage, content: [{ ...content, suggested_next_queries }] },
          newMessage,
        ],
      });
    }, 1000);
    setSelectedMenuOption(null);
  };

  const deleteSelectedMessages = () => {
    onDelete(selectedKeys);
  };

  const regenerate = () => {
    const userMessage = [...messages].reverse().find(m => m.role === 'user');
    const content = userMessage.content;
    const msg = {
      key: uuidv4(),
      role: 'user',
      content,
    };
    onSubmit({ messages: [...messages, msg] });
  };

  const critique = () => {
    const input = messages.slice(-2)[0].content;
    const completion = messages.slice(-1)[0].content[0].content;
    onCritique({ input, completion });
  };

  const handleSuggestPrompts = () => {
    suggestPrompts({ messages });
  };

  const onCheckAllChange = (ev) => {
    scrollTopRef.current = 0;
    if (ev.target.checked) {
      setCheckAll(true);
      const selectedUpdate = messages.reduce((a, m) => {
        if (Array.isArray(m.content)) {
          a[m.content[0].key] = {
            checked: true,
            index: m.index,
          };
        } else {
          a[m.key] = {
            checked: true,
            index: m.index,
          };
        }
        return a;
      }, {});
      setSelected(selectedUpdate);
      const selectedEntries = Object.entries(selectedUpdate).filter(([_, v]) => v.checked).map(([k, v]) => [k, v.index]);
      selectedEntries.sort((a, b) => a[1] < b[1] ? -1 : 1);
      const selectedKeys = selectedEntries.map(x => x[0]);
      if (typeof onSelected === 'function') {
        onSelected(selectedKeys);
      }
    } else {
      setCheckAll(false);
      setSelected({});
    }
    setIndeterminate(false);
    setTimeout(() => {
      scrollTopRef.current = null;
    }, 1000);
  };

  const createMessages = (selectedKeys) => {
    return selectedKeys.map(key => {
      const { message, content } = findMessage(key);
      if (content) {
        return {
          role: message.role,
          content: content.content,
          key: content.key,
        };
      }
      return message;
    });
  };

  useEffect(() => {
    if (messages.length) {
      const m = messages[messages.length - 1];
      if (m.role === 'assistant') {
        const content = m.content[0];
        if (content.search_hits?.length) {
          for (const { citation } of content.search_hits) {
            if (citation?.start) {
              const url = new URL(citation.url);
              const videoId = url.searchParams.get('v');
              let x, y;
              if (latestResponseRef.current) {
                const offset = getOffset(latestResponseRef.current);
                const rect = latestResponseRef.current.getBoundingClientRect();
                x = offset.left + rect.width + 124;
                y = offset.top;
              }
              setVideoParams({
                videoId,
                start: citation.start,
                x,
                y,
                quote: content.quote,
              });
              break;
            }
          }
        }
      }
    }
    setInput(null);
  }, [messages]);

  useLayoutEffect(() => {
    const el = messageScrollerRef.current;
    if (scrollTopRef.current === null) {
      el.scrollTop = el.scrollHeight;
    } else {
      el.scrollTop = scrollTopRef.current;
    }
  });

  const onCancelVideoPreview = () => {
    player.pauseVideo();
    setVideoParams({});
  };

  const onReady = (ev) => {
    setPlayer(ev.target);
  };

  const CitationSource = ({ key, source, index }) => {
    const { dataSourceId, dataSourceName, page, row, uri } = source;
    let displayURI;
    if (uri.startsWith('http') || uri.startsWith('/')) {
      const resourceName = uri.split('/').slice(-1)[0];
      if (typeof onShowCitation === 'function') {
        displayURI = (
          <Link onClick={() => onShowCitation(uri.slice(1))}>
            {resourceName}
          </Link>
        );
      } else {
        displayURI = (
          <Link to={uri} target="_blank" rel="noopener noreferrer">
            {resourceName}
          </Link>
        );
      }
    } else {
      displayURI = (
        <div>{uri}</div>
      );
    }
    return (
      <div key={key + '-source-' + index}
        style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 5 }}
      >
        {displayURI}
        {page || row ?
          <div>({page ? 'page' : 'chunk'} {page || row})</div>
          : null
        }
        {dataSourceName ?
          <Tag
            onClick={() => navigate(`/data-sources/${dataSourceId}`)}
            style={{ cursor: 'pointer' }}
          >
            {dataSourceName}
          </Tag>
          : null
        }
      </div>
    );
  };

  const SearchHit = ({ hit, index, messageIndex }) => {
    const { citation, information } = hit;
    const { last_modified, location, object_name, thumbnail, video_id, video_title, source_type, speaker, start } = citation;
    let source;
    if (object_name) {
      source = object_name.split('/').slice(-1)[0];
    } else {
      source = video_title || video_id;
    }
    // const desc = (
    //   <div>
    //     <div>{information}</div>
    //     <div style={{ fontStyle: 'italic', marginTop: 5 }}>
    //       Source: {source_type} ({source})
    //       {location?.value && location.value !== 'None'
    //         && <span>, {location.type} {location.value}</span>}
    //       {start && <span>, start {formatNumber(start)} secs</span>}
    //       {last_modified && ', ' + new Date(last_modified).toLocaleString()}
    //     </div>
    //   </div>
    // );
    const content = (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16, position: 'relative', width: 480 }}>
        <Button
          type="text"
          icon={<CloseOutlined />}
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={() => {
            scrollTopRef.current = messageScrollerRef.current.scrollTop;
            setHitOpen(cur => ({ ...cur, [`${messageIndex}-${index}`]: false }));
            setTimeout(() => {
              scrollTopRef.current = null;
            }, 1000);
          }}
        />
        <div style={{ margin: '0 auto' }}>
          <YouTube
            opts={{
              ...opts,
              playerVars: { ...opts.playerVars, start: isNaN(start) ? 0 : Math.floor(+start) },
            }}
            videoId={video_id}
          />
        </div>
        {speaker && speaker !== 'Unknown' ?
          <div className="speaker">Speaker: {speaker}</div>
          : null
        }
        <blockquote>
          <span>{source}</span>
          <Typography.Paragraph
            ellipsis={{ rows: 5, expandable: false, symbol: 'more' }}
          >
            {information}
          </Typography.Paragraph>
          {source_type === 'Chunk' ?
            <span style={{ marginTop: 5 }}>
              Source: {source_type}
              {location?.value && location.value !== 'None'
                && <>; {location.type === 'index' ? 'chunk index' : location.type} {location.value}</>}
              {start && <>; start {formatNumber(start)} secs</>}
              {last_modified && '; ' + new Date(last_modified).toLocaleString()}
            </span>
            : null
          }
        </blockquote>
      </div>
    );
    const desc = (
      <Popover
        content={content}
        placement="bottom"
        style={{ width: 700 }}
        trigger="click"
        open={hitOpen[`${messageIndex}-${index}`]}
        onOpenChange={v => {
          scrollTopRef.current = messageScrollerRef.current.scrollTop;
          setHitOpen(cur => ({ ...cur, [`${messageIndex}-${index}`]: v }));
          setTimeout(() => {
            scrollTopRef.current = null;
          }, 1000);
        }}
      >
        <Tag
          icon={(source_type === 'video') && <YoutubeOutlined />}
          style={{ cursor: 'pointer', display: 'flex', alignSelf: 'center', padding: '5px 16px', border: 'none', width: 450, maxWidth: 450 }}
        >
          <Ellipsis text={source} len={35} />
          {location?.value && location.value !== 'None'
            && <> ({location.type === 'index' ? 'chunk index' : location.type} {location.value})</>}
        </Tag>
      </Popover>
    );

    return (
      <>
        <div
          style={{ display: 'flex', alignSelf: 'center', padding: '5px 0' }}
        >
          {index + 1}.
        </div>
        {/* {thumbnail ?
          <div style={{ display: 'flex', gap: 16 }}>
            <Image src={thumbnail} preview={false} />
            {desc}
          </div>
          :
          desc
        } */}
        {desc}
        <div></div>
      </>
    );
  };

  const emptyContent = ({ content }) => {
    if (Array.isArray(content)) {
      return !(content.length && content[0].content);
    }
    return !content;
  };

  const AssistantMessageContent = ({ first, index, message }) => {

    const hits = message.content?.[0]?.search_hits || [];
    const vHits = hits.reduce((a, h) => {
      const id = h.citation?.video_id;
      if (id && !a[id]) {
        a[id] = h;
      }
      return a;
    }, {});
    const videos = Object.values(vHits);
    const nVideos = message.content?.[0]?.n_unique_videos || 0;
    const spkrs = videos.reduce((a, h) => {
      const speaker = h.citation?.speaker;
      if (speaker && speaker !== 'Unknown' && !a[speaker]) {
        a[speaker] = 1;
      }
      return a;
    }, {});
    // console.log('videos:', videos.map(v => [v.citation?.speaker, v.citation?.video_title, v]));
    const speakers = Object.keys(spkrs);
    // console.log('speakers:', speakers);
    const nSpeakers = speakers.length;
    // const nFlashcards = message.content?.[0]?.create_flash_cards?.length || 0;
    const nFlashcards = 3;

    const handleCreateFlashcards = () => {
      if (!message.content?.[0]?.create_flash_cards?.length) {
        const selectedVideos = message.content.flatMap(c => {
          return c.search_hits.map(h => h.citation.video_id);
        });
        if (typeof onCreateFlashCards === 'function') {
          onCreateFlashCards(selectedVideos);
        }
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('create_flash_cards');
    };

    const handleCreateMashup = () => {
      const selectedVideos = message.content.flatMap(c => {
        return c.search_hits.map(h => h.citation.video_id);
      });
      if (typeof onCreateMashup === 'function') {
        onCreateMashup(selectedVideos);
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('create_mashup');
    };

    const handleCreateOutline = () => {
      const selectedVideos = message.content.flatMap(c => {
        return c.search_hits.map(h => h.citation.video_id);
      });
      if (typeof onCreateOutline === 'function') {
        onCreateOutline(selectedVideos);
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('create_outline');
    };

    const handleCreateStudyNote = () => {
      const selectedVideos = message.content.flatMap(c => {
        return c.search_hits.map(h => h.citation.video_id);
      });
      if (typeof onCreateStudyNote === 'function') {
        onCreateStudyNote(selectedVideos);
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('create_study_note');
    };

    const handleCreateSummary = () => {
      const selectedVideos = message.content.flatMap(c => {
        return c.search_hits.map(h => h.citation.video_id);
      });
      if (typeof onCreateSummary === 'function') {
        onCreateSummary(selectedVideos);
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('create_summary');
    };

    const handleShowPeople = (ev, _speakerSkip) => {
      if (typeof _speakerSkip === 'undefined') {
        _speakerSkip = speakerSkip;
      }
      if (typeof onShowPeople === 'function') {
        const selectedSpeakers = speakers.slice(_speakerSkip, _speakerSkip + 3);
        const selectedVideos = selectedSpeakers.map(s => {
          const video = videos.find(v => v.citation?.speaker === s);
          return video.citation.video_id;
        });
        onShowPeople(selectedVideos, selectedSpeakers, _speakerSkip + 3);
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('show_people');
    };

    const handleShowPodcasts = () => {
      if (typeof onShowPodcasts === 'function') {
        onShowPodcasts();
      }
      setMenuOpen(cur => ({ ...cur, [index]: false }));
      setSelectedMenuOption('show_podcasts');
    };

    const x = firstSession ? 0 : 2;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: '0 8px' }}>
        {index === (4 - x) ?
          <Breadcrumb items={breadcrumbs.slice(0, 1)} />
          : null
        }
        {index > (5 - x) ?
          <Breadcrumb items={breadcrumbs} />
          : null
        }
        {message.content.map((c, i) => (
          <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
            {c.action === 'create_flash_cards' ?
              <div>
                <div className="flashcards-header">
                  Flashcards (click to flip)
                </div>
                <FlashcardArray
                  cards={c.content.slice(0, 3).map((f, i) => ({
                    id: i,
                    frontHTML: <Markdown>{f.question}</Markdown>,
                    backHTML: <Markdown>{f.answer}</Markdown>,
                  }))}
                />
              </div>
              : null
            }
            {c.content && c.action !== 'create_flash_cards' ? (
              typeof c.content === 'string' ? (
                /^[[{].*/.test(c.content) ?
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    {jsonToPlainText(JSON.parse(c.content))}
                  </div>
                  :
                  <Typography.Text
                    copyable
                    // copyable={{ icon: ['Copy', 'Copied'], tooltips: false }}
                    // style={{ whiteSpace: 'pre-wrap', color: 'rgb(255, 68, 42)' }}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {c.content}
                  </Typography.Text>
              ) :
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {jsonToPlainText(c.content)}
                </div>
            ) : null
            }
            {c.elaboration ?
              <Button
                onClick={() => setElaboratedKeys(cur => ({ ...cur, [c.key]: !cur[c.key] }))}
                size="small"
                type="link"
              >
                {elaboratedKeys[c.key] ? 'Hide' : 'Elaborate...'}
              </Button>
              : null
            }
            {elaboratedKeys[c.key] ?
              <div className="chat-card" style={{ marginTop: 16, width: '100%' }}>
                <Typography.Text
                  copyable
                // copyable={{ icon: ['Copy', 'Copied'], tooltips: false }}
                >
                  {c.elaboration}
                </Typography.Text>
              </div>
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'show_podcasts' ? (
              c.search_hits?.length ?
                <div>
                  <div className="flashcards-header">
                    Podcasts
                  </div>
                  <div className="carousel podcasts" style={{ position: 'relative' }}>
                    <Carousel arrows>
                      {videos.slice(skip, skip + 3).map(h => (
                        <div>
                          {h.citation?.speaker ?
                            <div className="speaker" style={{ marginBottom: 6 }}>
                              {h.citation.speaker === 'Unknown' ? <span>&nbsp;</span> : h.citation.speaker}
                            </div>
                            : null
                          }
                          <YouTube
                            opts={opts}
                            videoId={h.citation.video_id}
                          />
                        </div>
                      ))}
                    </Carousel>
                    {(skip + 3) < nVideos ?
                      <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <Button
                          type="link"
                          onClick={() => setSkip(cur => cur + 3)}
                        >
                          show more...
                        </Button>
                      </div>
                      : null
                    }
                  </div>
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'show_people' ? (
              c.show_people?.length && !loading ?
                <div>
                  <div className="flashcards-header">
                    People - big ideas
                  </div>
                  <div className="carousel people" style={{ position: 'relative' }}>
                    <Carousel arrows>
                      {c.show_people?.filter(p => p.person !== 'Unknown').map(p => (
                        <div>
                          <div className="speaker" style={{ marginBottom: 6 }}>
                            {p.person}
                          </div>
                          <Typography.Paragraph
                            ellipsis={{ rows: 6, expandable: false, symbol: 'more' }}
                          >
                            {p.idea}
                          </Typography.Paragraph>
                        </div>
                      ))}
                    </Carousel>
                    {(speakerSkip + 3) < nSpeakers ?
                      <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <Button
                          type="link"
                          onClick={(ev) => handleShowPeople(ev, speakerSkip + 3)}
                        >
                          show more...
                        </Button>
                      </div>
                      : null
                    }
                  </div>
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 310 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'create_flash_cards' ? (
              c.create_flash_cards?.length ?
                <div>
                  <div className="flashcards-header">
                    Flashcards (click to flip)
                  </div>
                  <FlashcardArray
                    cards={(c.create_flash_cards || []).map((f, i) => ({
                      id: i,
                      frontHTML: <Markdown>{f.question}</Markdown>,
                      backHTML: <Markdown>{f.answer}</Markdown>,
                    }))}
                  />
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'create_summary' ? (
              c.create_summary ?
                <div>
                  <div className="flashcards-header">
                    Summary
                  </div>
                  <Typography.Paragraph
                    ellipsis={{ rows: 12, expandable: false, symbol: 'more' }}
                  >
                    {c.create_summary}
                  </Typography.Paragraph>
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 310 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'create_outline' ? (
              c.create_outline ?
                <div>
                  <div className="flashcards-header">
                    Outline
                  </div>
                  <Markdown className="markdown">
                    {c.create_outline.join('\n')}
                  </Markdown>
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 310 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'create_study_note' ? (
              c.create_study_note ?
                <div>
                  <div className="flashcards-header">
                    Study notes
                  </div>
                  <Markdown className="markdown">
                    {c.create_study_note}
                  </Markdown>
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 310 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && selectedMenuOption === 'create_mashup' ? (
              c.create_mashup ?
                <div>
                  <div className="flashcards-header">
                    Ideas mashup
                  </div>
                  <Markdown className="markdown">
                    {c.create_mashup}
                  </Markdown>
                  <Button
                    size="small"
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setTimeout(() => {
                        setMenuOpen(cur => ({ ...cur, [index]: true }));
                        setSelectedMenuOption(null);
                      }, 200);
                    }}
                    style={{ background: '#FF3317', border: 0, color: '#F1E8D4', fontWeight: 600, marginTop: 8 }}
                  >
                    Menu
                  </Button>
                </div>
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 310 }}>
                  <Spin size="large" />
                </div>
            )
              : null
            }
            {messages.length === (index + 1) && c.search_hits?.length && (menuOpen[index] || typeof menuOpen[index] === 'undefined') ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 8, height: 310 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
                  {/* <div style={{ display: 'inline-block' }}>
                    <CircleMenu
                      // onMenuToggle={menuActive => setMenuOpen(cur => ({ ...cur, [index]: menuActive }))}
                      // open={menuOpen[index]}
                      startAngle={-90}
                      rotationAngle={360}
                      radius={5}
                      rotationAngleInclusive={false}
                      className="circular-menu"
                      onMenuToggle={menuActive => {
                        if (!menuActive) {
                          setSelectedMenuOption(null);
                        }
                      }}
                    >
                      <CircleMenuItem
                        tooltip="Flashcards"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleCreateFlashcards}
                      >
                        <ThunderboltOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                      <CircleMenuItem
                        tooltip="Podcasts"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleShowPodcasts}
                      >
                        <YoutubeOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                      <CircleMenuItem
                        tooltip="Summary"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleCreateSummary}
                      >
                        <ShrinkOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                      <CircleMenuItem
                        tooltip="Study notes"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleCreateStudyNote}
                      >
                        <ReadOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                      <CircleMenuItem
                        tooltip="Outline"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleCreateOutline}
                      >
                        <ProfileOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                      <CircleMenuItem
                        tooltip="Ideas mashup"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleCreateMashup}
                      >
                        <BulbOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                      <CircleMenuItem
                        tooltip="People"
                        tooltipPlacement={TooltipPlacement.Right}
                        className="circular-menu-item"
                        onClick={handleShowPeople}
                      >
                        <TeamOutlined style={{ fontSize: '2em' }} />
                      </CircleMenuItem>
                    </CircleMenu>
                  </div> */}
                  <Button
                    className="in-chat-menu-btn"
                    onClick={handleCreateFlashcards}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyItems: 'center',
                      gap: 8,
                      padding: 16,
                      height: 100,
                      width: 144,
                    }}
                  >
                    <div>
                      <ThunderboltOutlined style={{ fontSize: '1.2em' }} />
                      <div style={{ fontSize: '1.1em', fontWeight: 600 }}>Flashcards</div>
                      <div style={{ fontSize: '1.1em' }}>
                        {nFlashcards}
                      </div>
                    </div>
                  </Button>
                  <Button
                    className="in-chat-menu-btn"
                    onClick={handleShowPodcasts}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyItems: 'center',
                      gap: 8,
                      padding: 16,
                      height: 100,
                      width: 144,
                    }}
                  >
                    <div>
                      <YoutubeOutlined style={{ fontSize: '1.2em' }} />
                      <div style={{ fontSize: '1.1em', fontWeight: 600 }}>Conversations</div>
                      <div style={{ fontSize: '1.1em' }}>
                        {nVideos}
                      </div>
                    </div>
                  </Button>
                  <Button
                    className="in-chat-menu-btn"
                    onClick={handleShowPeople}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyItems: 'center',
                      gap: 8,
                      padding: 16,
                      height: 100,
                      width: 144,
                    }}
                  >
                    <div>
                      <TeamOutlined style={{ fontSize: '1.2em' }} />
                      <div style={{ fontSize: '1.1em', fontWeight: 600 }}>Leaders</div>
                      <div style={{ fontSize: '1.1em' }}>
                        {nSpeakers}
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
              : null
            }
            {c.suggested_next_queries?.length ?
              <>
                <div
                  className="text-secondary"
                  style={{ marginTop: 16 }}
                >
                  {message.isQuiz ? 'Would you like to take a quiz?' : (first ? 'What would you like to discuss?' : 'Suggested next topics')}
                </div>
                <Space wrap style={{ margin: '8px 0' }}>
                  {c.suggested_next_queries.map((q, i) => (
                    <Button key={'q-' + i}
                      type={q.selected ? 'primary' : 'default'}
                      onClick={() => {
                        if (messages.length === (index + 1)) {
                          handleSuggestedQuery(q.query, c.hideUserMessage);
                        }
                      }}
                    >
                      {q.short_label}
                    </Button>
                  ))}
                </Space>
              </>
              : null
            }
            {isAdmin && (c.citation_metadata || c.search_hits?.length) ?
              <Collapse ghost
                activeKey={activeKey}
                onChange={setActiveKey}
                items={[
                  {
                    key: '1',
                    label: (activeKey[0] === '1' ? 'Hide' : 'Show') + ' internal dialog',
                    children: (
                      <>
                        {c.citation_metadata?.citation_sources?.length ?
                          <div style={{ display: 'flex', alignItems: 'flex-start', fontSize: '14px', gap: 5, lineHeight: '22px' }}>
                            <div>Citations:</div>
                            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 16 }}>
                              {c.citation_metadata.citation_sources.map((s, i) => (
                                <CitationSource key={c.key} source={s} index={i} />
                              ))}
                            </div>
                          </div>
                          : null
                        }
                        {c.search_hits?.length ?
                          <div style={{ marginTop: 10 }}>
                            <div>Search hits</div>
                            <div style={{ display: 'grid', gridRowGap: 14, gridTemplateColumns: '25px auto 100%', marginTop: 5 }}>
                              {c.search_hits.map((h, i) => (
                                <SearchHit hit={h} index={i} messageIndex={index} />
                              ))}
                            </div>
                          </div>
                          : null
                        }
                      </>
                    )
                  }
                ]}
              />
              : null
            }
            {isAdmin ?
              <div className="text-secondary"
                style={{ marginTop: 4 }}
              >
                {c.model}
              </div>
              : null
            }
          </div>
        ))}
      </div>
    );
  }

  const AssistantMessage = ({ first, index, isLatestResponse, message, onChange }) => {
    // if (!isLatestResponse && emptyContent(message)) {
    //   return null;
    // }
    if (!selectable || (first && !selectMultiple)) {
      return (
        <div
          className="chatline assistant"
          key={message.key}
          ref={isLatestResponse ? latestResponseRef : null}
        >
          <div className="ant-radio"></div>
          <div className="avatar"><Avatar style={{ background: '#FF3317' }}>PQ</Avatar></div>
          <div className="content" style={{ maxWidth: '100%' }}>
            <AssistantMessageContent
              first={first}
              index={index}
              message={message}
            />
          </div>
        </div>
      );
    }
    if (selectMultiple) {
      // return (
      //   <Checkbox value={message.key} onChange={onChange} checked={selected[message.key]?.checked}>
      //     <div className="chatline assistant">
      //       <div className="avatar"><Avatar>A</Avatar></div>
      //       <div className="content">
      //         <Space size="large">
      //           {message.content.map((c, i) => (
      //             <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
      //               <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>{c.content}</Typography.Text>
      //               <div className="text-secondary" style={{ marginTop: 8 }}>{c.model}</div>
      //             </div>
      //           ))}
      //         </Space>
      //       </div>
      //     </div>
      //   </Checkbox>
      // );
      return (
        <div key={message.key} className="chatline assistant">
          <div className="avatar"><Avatar style={{ background: '#FF3317' }}>PQ</Avatar></div>
          <div className="content">
            <Space size="large">
              {message.content.map((c, i) => (
                <div key={c.key} className={i > 0 ? 'chat-sep' : ''}
                  style={{ display: 'flex', alignItems: 'center', gap: 0 }}
                >
                  <div style={{ width: 16 }}>
                    <Checkbox
                      checked={selected[c.key]?.checked}
                      onChange={onChange}
                      value={c.key}
                    />
                  </div>
                  {/* <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>{c.content}</Typography.Text>
                  <div className="text-secondary" style={{ marginTop: 4 }}>{c.model}</div> */}
                  <AssistantMessageContent
                    first={first}
                    index={index}
                    message={message}
                  />
                </div>
              ))}
            </Space>
          </div>
        </div>
      );
    }
    return (
      <Radio key={message.key} value={message.key}>
        <div className="chatline assistant">
          <div className="avatar"><Avatar>A</Avatar></div>
          <div className="content">
            <Space size="large">
              {message.content.map((c, i) => (
                <div key={c.key} className={i > 0 ? 'chat-sep' : ''}>
                  {/^[[{].*/.test(c.content) ?
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {jsonToPlainText(JSON.parse(c.content))}
                    </div>
                    :
                    <Typography.Text copyable style={{ whiteSpace: 'pre-wrap' }}>{c.content}</Typography.Text>
                  }
                  <div className="text-secondary" style={{ marginTop: 4 }}>{c.model}</div>
                </div>
              ))}
            </Space>
          </div>
        </div>
      </Radio>
    );
  };

  const UserContent = ({ message }) => {
    const { key, content } = message;
    if (Array.isArray(content)) {
      return (
        <div style={{
          alignContent: 'start',
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: 16,
          textAlign: 'left'
        }}>
          {content.map((c, i) => {
            if (c.type === 'text') {
              return (
                <div key={key + '-' + i}>
                  <Typography.Text
                    copyable
                    // copyable={{ icon: ['Copy', 'Copied'], tooltips: false }}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {c.text}
                  </Typography.Text>
                </div>
              );
            } else if (c.type === 'image_url') {
              return (
                <div key={key + '-' + i}>
                  <Image src={c.image_url.url} width={200}
                    preview={{
                      onVisibleChange: (visible) => {
                        previewVisible = visible;
                      },
                    }}
                  />
                </div>
              );
            }
          })}
        </div>
      )
    }
    return (
      <Typography.Text key={key}
        copyable
        // copyable={{ icon: ['Copy', 'Copied'], tooltips: false }}
        // style={{ whiteSpace: 'pre-wrap', color: 'rgb(57, 111, 155)' }}
        // style={{ whiteSpace: 'pre-wrap', color: 'rgb(71, 139, 194)' }}
        // style={{ whiteSpace: 'pre-wrap', color: 'rgb(146, 146, 148)' }}
        // style={{ whiteSpace: 'pre-wrap', color: '#039c9c' }}
        style={{ whiteSpace: 'pre-wrap', fontStyle: 'italic' }}
      >
        {content}
      </Typography.Text>
    );
  }

  const UserMessage = ({ message, onChange }) => {
    if (message.hidden) {
      return null;
    }
    if (selectMultiple) {
      return (
        <Checkbox
          checked={selected[message.key]?.checked}
          onChange={onChange}
          value={message.key}
          style={{ paddingLeft: 40 }}
          className="user-checkbox"
        >
          <div className="chatline user">
            <div className="content">
              <UserContent message={message} />
            </div>
            <div className="avatar"><Avatar>U</Avatar></div>
          </div>
        </Checkbox>
      );
    }
    return (
      <div className="chatline user">
        <div className="content">
          <UserContent message={message} />
        </div>
        {/* <div className="avatar"><Avatar style={{ background: 'rgb(27, 50, 72)' }}>U</Avatar></div> */}
        {/* <div className="avatar"><Avatar style={{ background: 'rgb(39, 69, 94)' }}>U</Avatar></div> */}
        <div className="avatar"><Avatar>U</Avatar></div>
      </div>
    );
  };

  const Message = ({ first, index, isLatestResponse, message, onChange }) => {
    if (message.role === 'assistant') {
      return (
        <AssistantMessage
          first={first}
          index={index}
          isLatestResponse={isLatestResponse}
          message={message}
          onChange={onChange}
        />
      );
    }
    if (message.role === 'user') {
      return (
        <UserMessage message={message} onChange={onChange} />
      );
    }
    return null;
  };

  const Messages = ({ messages, onChange, value }) => {
    const latestResponseIndex = messages.findLastIndex(m => m.role === 'assistant');
    if (selectable) {
      if (selectMultiple) {
        return (
          <div style={{ marginBottom: 24 }}>
            {messages.map((m, i) => (
              <Message
                key={m.key}
                first={i === 0}
                index={i}
                isLatestResponse={i === latestResponseIndex}
                message={m}
                onChange={onChange}
              />
            ))}
          </div>
        );
      }
      return (
        <Radio.Group onChange={onChange} value={value}>
          {messages.map((m, i) => (
            <Message
              key={m.key}
              first={i === 0}
              index={i}
              isLatestResponse={i === latestResponseIndex}
              message={m}
            />
          ))}
        </Radio.Group>
      );
    }
    return (
      <div style={{ marginBottom: 24 }}>
        {messages.map((m, i) => (
          <Message
            key={m.key}
            first={i === 0}
            index={i}
            isLatestResponse={i === latestResponseIndex}
            message={m}
          />
        ))}
      </div>
    )
  };

  const Loading = ({ loading }) => {
    if (!loading) {
      return null;
    }
    return (
      <div style={{ margin: '20px 0', textAlign: 'center' }}>
        <Spin />
      </div>
    );
  };

  const MessagesSection = ({ disabled, loading, messages, onChange, value }) => {
    if (disabled) {
      return (
        <div></div>
      );
    }
    return (
      <div ref={messageScrollerRef} className="message-scroller">
        <div>
          {selectMultiple && messages.length > 0 ?
            <>
              <Checkbox
                checked={checkAll}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                style={{ marginBottom: 0, paddingLeft: 48 }}
              >
                {checkAll ? 'Unselect all' : 'Select all'}
              </Checkbox>
              <Divider style={{ marginBottom: 16, marginTop: 8 }} />
            </>
            : null
          }
          <Messages
            messages={messages}
            onChange={onChange}
            value={value}
          />
          <Loading loading={loading} />
        </div>
      </div>
    );
  };

  const Actions = ({
    deleteSelectedMessages,
    disabled,
    enableCritique,
    hasMessages,
    hasSelected,
    regenerate,
    saveChatSession,
    startNewChatSession,
    visible,
  }) => {
    if (!visible) {
      return null;
    }
    return (
      <div className="chat-actions">
        <Button type="default" size="small"
          disabled={disabled || !hasMessages}
          onClick={startNewChatSession}
        >
          New topic
        </Button>
        {onCreateNote ?
          <Button type="default" size="small"
            disabled={disabled || !hasSelected}
            onClick={handleCreateNote}
          >
            Create note
          </Button>
          : null
        }
        {onSave ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages}
            onClick={saveChatSession}
          >
            Save Session
          </Button>
          : null
        }
        {onGetQuiz ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages}
            loading={quizLoading}
            onClick={onGetQuiz}
            style={{ background: 'rgb(255, 51, 23)' }}
          >
            Take Quiz
          </Button>
          : null
        }
        {regenerate && false ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages}
            onClick={regenerate}
          >
            Regenerate
          </Button>
          : null
        }
        {onDelete ?
          <Button danger type="primary" size="small"
            disabled={disabled || !hasSelected}
            onClick={deleteSelectedMessages}
          >
            Delete
          </Button>
          : null
        }
        {onCritique ?
          <Button type="primary" size="small"
            disabled={disabled || !hasMessages || !enableCritique}
            onClick={critique}
          >
            Evaluate
          </Button>
          : null
        }
        {traceId ?
          <div style={{ color: '#1677ff', flex: 1, marginRight: 36, textAlign: 'end', whiteSpace: 'nowrap' }}>
            <Link to={`/traces/${traceId}`}>Latest trace...</Link>
          </div>
          : null
        }
      </div>
    );
  };

  return (
    <>
      <div
        className="chat"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: 756,
          height: 'calc(100vh - 72px)',
          maxHeight: 'calc(100vh - 72px)',
        }}
      >
        <MessagesSection
          disabled={disabled}
          loading={loading}
          messages={messages}
          onChange={(ev) => handleChange(ev.target.value)}
          value={selectedKeys[0]}
        />
        {acceptFreeTextInput ?
          <>
            <div ref={tourRefs?.prompt}>
              <MessageInput
                appOptions={[]}
                disabled={disabled}
                loading={loading}
                onChange={setInput}
                onClick={() => setInput(null)}
                onSubmit={handleSubmit}
                placeholder={placeholder}
                value={input}
              />
            </div>
            <Actions
              critique={critique}
              deleteSelectedMessages={deleteSelectedMessages}
              disabled={disabled}
              enableCritique={enableCritique}
              handleSuggestPrompts={handleSuggestPrompts}
              hasMessages={hasMessages}
              hasSelected={hasSelected}
              regenerate={regenerate}
              saveChatSession={handleSave}
              startNewChatSession={handleReset}
              visible={enableActions}
              handleCreateNote={handleCreateNote}
            />
          </>
          : null
        }
        {false &&
          <div style={{ marginTop: 20 }}>
            <Space>
              {videoParams.videoId ?
                <div
                  className="chat-card"
                // style={{
                //   top: videoParams.y,
                //   left: videoParams.x,
                // }}
                >
                  <YouTube
                    onReady={onReady}
                    opts={{
                      ...opts,
                      playerVars: { ...opts.playerVars, start: videoParams.start },
                    }}
                    videoId={videoParams.videoId}
                  />
                  {/* <div>
                  <Typography.Paragraph>
                    <blockquote>{videoParams.quote}</blockquote>
                  </Typography.Paragraph>
                </div> */}
                </div>
                : null
              }
              {/* <p>Here are the top 3 out of 10 videos</p> */}
              {messages.length > 5 && !score ?
                <div
                  className="chat-card"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Button onClick={onGetQuiz}>Take Quiz</Button>
                </div>
                : null
              }
              {score > 0 && false ?
                <div
                  className="chat-card"
                >
                  <div style={{ fontWeight: 600 }}>Leaderboard</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                    <div>{user?.displayName}</div>
                    <div>{score}</div>
                  </div>
                </div>
                : null
              }
              {image && false ?
                <div
                  className="chat-card"
                >
                  <Image src={image.imageUrl} />
                  <div>
                    <Typography.Paragraph>
                      <blockquote>{image.title}</blockquote>
                    </Typography.Paragraph>
                  </div>
                </div>
                : null
              }
            </Space>
          </div>
        }
      </div>
    </>
  );
}

const MessageInput = ({ appOptions, disabled, loading, onChange, onClick, onSubmit, placeholder, value }) => {

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (value) => {
    const mention = appOptions.find(a => value.includes(a.value));
    if (!mention) {
      setSelectedOption(null);
    }
    onChange(value);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onSubmit(selectedOption?.value);
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Mentions
          allowClear
          autoSize={{ minRows: 1, maxRows: 14 }}
          filterOption={() => !selectedOption}
          onPressEnter={(ev) => {
            if (!ev.shiftKey) {
              ev.preventDefault();
              if (!disabled && ev.target.value) {
                handleSubmit(ev);
              }
            }
          }}
          style={{ flex: 1 }}
          onChange={handleChange}
          onSelect={handleSelect}
          onClick={onClick}
          options={appOptions}
          placeholder={placeholder}
          value={value}
          variant="borderless"
        />
        {/* <div style={{ marginLeft: 3 }}>
          <Button type="text"
            disabled={disabled || loading || !value}
            icon={<SendOutlined />}
            onClick={handleSubmit}
          />
        </div> */}
      </div>
      {/* <p className="text-secondary"
        style={{ lineHeight: '32px' }}
      >
        Press Shift+Enter to insert a new line.
      </p> */}
    </div>
  );
};
