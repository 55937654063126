import { useState } from 'react';
import {
  Flex,
  Form,
  Modal,
  Segmented,
} from 'antd';

import NodeForm from './NodeForm';
import PropertyForm from './PropertyForm';

export default function AddColumnModal({
  nodeOptions,
  onCancel,
  onSubmit,
  open,
}) {

  const [formType, setFormType] = useState('property');

  const [form] = Form.useForm();

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  }

  const handleOk = async () => {
    const values = await form.validateFields();
    onSubmit({ formType, values });
    form.resetFields();
  };

  const DataForm = () => {
    if (formType === 'property') {
      return <PropertyForm form={form} nodeOptions={nodeOptions} />
    }
    if (formType === 'node') {
      return <NodeForm form={form} />
    }
    return null;
  }

  return (
    <Modal
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      okText="Save"
      title="Add Column"
    >
      <Flex vertical gap="middle">
        <div>
          <Segmented
            size="small"
            onChange={setFormType}
            options={[
              {
                value: 'property',
                label: 'Property',
              },
              {
                value: 'relationship',
                label: 'Relationship',
              },
              {
                value: 'node',
                label: 'Node',
              },
            ]}
            value={formType}
          />
        </div>
        <DataForm />
      </Flex>
    </Modal>
  );
}