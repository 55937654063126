export const WandIcon = `<svg width="15px" height="15px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g id="SLICES-64px" transform="translate(-810.000000, -400.000000)"></g>
  <g id="ICONS" transform="translate(-805.000000, -395.000000)">
    <g id="des-wand-2" transform="translate(810.000000, 402.000000)">
      <polygon id="Fill-513" fill="#4BAADC" points="0 48 4 52 34 22 30 18"></polygon>
      <polygon id="Fill-514" fill="#FFFFFF" points="38 10 30 18 34 22 42 14"></polygon>
      <polygon id="Stroke-515" stroke="#1E69A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" points="4 52 0 48 38 10 42 14"></polygon>
      <path d="M34,22 L30,18" id="Stroke-516" stroke="#1E69A0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M38,4 L38,0" id="Stroke-517" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M48,14 L52,14" id="Stroke-518" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M28,14 L24,14" id="Stroke-519" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M38,24 L38,28" id="Stroke-520" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M33,5.3398 L31,1.8748" id="Stroke-521" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M46.6602,9 L50.1252,7" id="Stroke-522" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M43,22.6602 L45,26.1252" id="Stroke-523" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M29.3398,9 L25.8748,7" id="Stroke-524" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M43,5.3398 L45,1.8748" id="Stroke-525" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M46.6602,19 L50.1252,21" id="Stroke-526" stroke="#4BAADC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </g>
  </g>
</g></svg>`;
