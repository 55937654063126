import React from 'react';
import './HomeScreen.css';

import { Button } from 'antd';

export default function HomeScreen({ createCall, startHairCheck }) {
  const startDemo = () => {
    createCall().then(({ url, correlationId }) => {
      startHairCheck(url, correlationId);
    });
  };

  return (
    <div className="home-screen" style={{ marginTop: 100 }}>
      <Button type="primary" onClick={startDemo}>
        Start Interview
      </Button>
      <p className="small" style={{ marginTop: 40 }}>
        Select "Allow" to use your camera and microphone for this call if prompted.
      </p>
    </div>
  );
}
