import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const metricSetsSlice = createSlice({
  name: 'metricSets',
  initialState: {
    correlationId: null,
    loaded: false,
    loading: false,
    metricSets: {},
  },
  reducers: {
    removeMetricSets: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.metricSets[id];
      }
    },
    setCorrelationId: (state, action) => {
      state.correlationId = action.payload.correlationId;
    },
    setMetricSets: (state, action) => {
      for (const metricSet of action.payload.metricSets) {
        state.metricSets[metricSet.id] = metricSet;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
  }
});

export const {
  removeMetricSets,
  setCorrelationId,
  setMetricSets,
  startLoad,
} = metricSetsSlice.actions;

export const getMetricSetsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/metric-sets?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setMetricSets({ metricSets: res.data }));
};

export const getMetricSetAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/metric-sets/${id}`;
  const res = await http.get(url);
  dispatch(setMetricSets({ metricSets: [res.data] }));
};

export const createMetricSetAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/metric-sets';
  const res = await http.post(url, values);
  dispatch(setMetricSets({ metricSets: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const updateMetricSetAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/metric-sets/${id}`;
  const res = await http.put(url, values);
  dispatch(setMetricSets({ metricSets: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const deleteMetricSetsAsync = (ids) => async (dispatch) => {
  const url = `/api/metric-sets?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeMetricSets({ ids }));
};

export const materializeMetricSetAsync = ({ correlationId, id, workspaceId }) => async (dispatch) => {
  const url = `/api/metric-sets/materialize/${id}`;
  const res = await http.post(url, { correlation_id: correlationId, worksspace_id: workspaceId });
  // dispatch(setMetricSets({ metricSets: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const selectMetricSets = (state) => state.metricSets.metricSets;

export const selectLoaded = (state) => state.metricSets.loaded;

export const selectLoading = (state) => state.metricSets.loading;

export const selectCorrelationId = (state) => state.metricSets.correlationId;

export default metricSetsSlice.reducer;
