import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const graphSlice = createSlice({
  name: 'graph',
  initialState: {
    graphLegend: {},
    graphSize: 0,
    graphs: {},
    loaded: false,
    loading: false,
    nodes: [],
    nodesMetadata: {},
    relationshipsMetadata: {},
    nodeOptions: {},
    nodeLabels: [],
  },
  reducers: {
    setGraphLegend: (state, action) => {
      state.graphLegend = action.payload.graphLegend;
    },
    setGraphSize: (state, action) => {
      state.graphSize = action.payload.graphSize;
    },
    setGraph: (state, action) => {
      const { correlationId, graph } = action.payload;
      state.graphs[correlationId] = graph;
      state.loading = false;
      state.loaded = true;
    },
    startLoad: (state, action) => {
      state.loading = true;
      state.loaded = false;
    },
    setNodesMetadata: (state, action) => {
      state.nodesMetadata = action.payload.nodesMetadata;
      state.loading = false;
      state.loaded = true;
    },
    setRelationshipsMetadata: (state, action) => {
      state.relationshipsMetadata = action.payload.relationshipsMetadata;
    },
    setNodeOptions: (state, action) => {
      state.nodeOptions = action.payload.nodeOptions;
    },
    setNodes: (state, action) => {
      state.nodes = action.payload.nodes;
    },
    setNodeLabels: (state, action) => {
      state.nodeLabels = action.payload.nodeLabels;
    },
  }
});

export const {
  setGraphLegend,
  setGraphSize,
  setGraph,
  startLoad,
  setNodesMetadata,
  setRelationshipsMetadata,
  setNodeOptions,
  setNodes,
  setNodeLabels,
} = graphSlice.actions;

export const addPropertyTypeAsync = ({ correlationId, ...req }) => async (dispatch) => {
  const url = `/api/graph/property_types`;
  const res = await http.post(url, req);
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const savePropertyAsync = ({ correlationId, ...req }) => async (dispatch) => {
  const url = `/api/graph/properties`;
  const res = await http.post(url, req);
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const getGraphLegendAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/graph/legend?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setGraphLegend({ graphLegend: res.data }));
};

export const getGraphSizeAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/graph/count?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setGraphSize({ graphSize: res.data }));
};

export const getGraphAsync = ({ correlationId, limit = 1000, skip = 0, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph?workspace_id=${workspaceId}&limit=${limit}&skip=${skip}`;
  const res = await http.get(url);
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const runCypherQuery = ({ correlationId, query, workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/cypher-query`;
  const res = await http.post(url, { query, workspaceId });
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const runGraphQuery = ({ correlationId, queries, workspaceId, filters, params }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/query-graph`;
  const res = await http.post(url, { queries, workspaceId, filters, params });
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const searchGraphAsync = ({ correlationId, query, workspaceId }) => async (dispatch) => {
  const url = `/api/graph/search`;
  const res = await http.post(url, { q: query, workspaceId });
  dispatch(setGraph({ correlationId, graph: res.data }));
};

export const getNodeLabelsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/node-labels?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodeLabels({ nodeLabels: res.data }));
};

export const getNodesAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodes({ nodes: res.data }));
};

export const getNodeOptionsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes-options?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodeOptions({ nodeOptions: res.data }));
};

export const getNodesMetadataAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/graph/nodes-metadata?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setNodesMetadata({ nodesMetadata: res.data }));
};

export const getRelationshipsMetadataAsync = ({ workspaceId }) => async (dispatch) => {
  const url = `/api/graph/relationships/metadata?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setRelationshipsMetadata({ relationshipsMetadata: res.data }));
};

export const selectGraphLegend = (state) => state.graph.graphLegend;

export const selectGraphSize = (state) => state.graph.graphSize;

export const selectGraphs = (state) => state.graph.graphs;

export const selectLoaded = (state) => state.graph.loaded;

export const selectLoading = (state) => state.graph.loading;

export const selectNodesMetadata = (state) => state.graph.nodesMetadata;

export const selectRelationshipsMetadata = (state) => state.graph.relationshipsMetadata;

export const selectNodeOptions = (state) => state.graph.nodeOptions;

export const selectNodes = (state) => state.graph.nodes;

export const selectNodeLabels = (state) => state.graph.nodeLabels;

export default graphSlice.reducer;
