import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Space, Table, message } from 'antd';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  deleteInferenceRunsAsync,
  getInferenceRunsAsync,
  runInferenceAsync,
  selectLoading,
  selectInferenceRuns,
  selectProcessed,
  selectProcessing,
} from './inferenceRunsSlice';

export function InferenceRunsList() {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const inferenceRuns = useSelector(selectInferenceRuns);
  const loading = useSelector(selectLoading);
  const processed = useSelector(selectProcessed);
  const processing = useSelector(selectProcessing);

  const data = useMemo(() => {
    const list = Object.values(inferenceRuns).map(a => ({
      key: a.id,
      name: a.name,
      created: a.created,
      createdBy: a.createdBy,
      modified: a.modified,
    }));
    list.sort((a, b) => a.name < b.name ? -1 : 1);
    return list;
  }, [inferenceRuns]);

  // console.log('data:', data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: '/inference/new',
      title: 'Inference Tasks',
    }));
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getInferenceRunsAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  const onDelete = () => {
    dispatch(deleteInferenceRunsAsync(selectedRowKeys));
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const runInference = (key) => {
    const run = inferenceRuns[key];
    if (run) {
      dispatch(runInferenceAsync({
        activity: run.activity,
        setting: run.setting,
        nodeLabels: run.nodeLabels,
        workspaceId: selectedWorkspace.id,
        domain: selectedWorkspace.key,
      }));
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, { key, name }) => <Link to={`/inference/${key}`}>{name}</Link>
    },
    {
      title: 'Created by',
      dataIndex: 'createdBy',
    },
    {
      title: 'Last updated',
      dataIndex: 'modified',
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <Button type="link"
            onClick={() => navigate(`/inference/${record.key}`)}
            style={{ paddingLeft: 0 }}
          >
            Edit
          </Button>
          <Button type="link"
            loading={processing}
            onClick={() => runInference(record.key)}
            style={{ paddingLeft: 0 }}
          >
            Run
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {contextHolder}
      <div style={{ marginTop: 20 }}>
        <p style={{ marginBottom: 16 }}>
          An inference task will augment the knowledge base by using AI to infer
          additional entities and relationships that are mentioned in the content
          of the acquired data. Entities include People, Organisations, Products, 
          Policies, Objectives, etc. Relationships show how entities are linked 
          together.
        </p>
        <div style={{ marginBottom: 16 }}>
          <Button danger type="primary" onClick={onDelete} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ hideOnSinglePage: true }}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};
