import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space } from 'antd';

import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';

import {
  createUploadAsync,
  getUploadAsync,
  selectLoaded,
  selectUploads,
  updateUploadAsync,
} from './fileUploaderSlice';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const typeOptions = [
  {
    label: 'Google Drive',
    value: 'gdrive',
  },
  {
    label: 'YouTube Channel',
    value: 'youtube',
  },
];

export function UploadForm() {

  const loaded = useSelector(selectLoaded);
  const uploads = useSelector(selectUploads);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const typeValue = Form.useWatch('type', form);

  const id = location.pathname.match(/\/uploads\/(.*)/)[1];
  const isNew = id === 'new';
  const upload = uploads[id];

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Data Source',
    }));
    if (!isNew) {
      dispatch(getUploadAsync(id));
    }
  }, []);

  const backToList = () => {
    navigate('/uploads');
  }

  const onFinish = (values) => {
    let filename;
    if (values.type === 'youtube') {
      filename = values.query;
    } else if (values.type === 'gdrive') {
      filename = values.pattern;
    }
    if (isNew) {
      dispatch(createUploadAsync({
        values: {
          ...values,
          filename,
          workspaceId: selectedWorkspace.id,
        },
      }));
    } else {
      dispatch(updateUploadAsync({
        id,
        values: { ...values, filename },
      }));
    }
    navigate('/uploads');
  };

  if (!loaded) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Form
        {...layout}
        autoComplete="off"
        form={form}
        onFinish={onFinish}
        initialValues={upload}
      >
        <Form.Item
          label="Source"
          name="type"
          rules={[
            {
              required: true,
              message: "Please select the source.",
            },
          ]}
        >
          <Select allowClear
            options={typeOptions}
            optionFilterProp="label"
            placeholder="Select type"
          />
        </Form.Item>
        {typeValue === 'youtube' ?
          <Form.Item
            label="Channel Search"
            name="query"
          >
            <Input />
          </Form.Item>
          : null
        }
        {typeValue === 'gdrive' ?
          <>
            <Form.Item
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Root Folder ID"
              name="rootFolderId"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="File Pattern"
              name="pattern"
            >
              <Input />
            </Form.Item>
          </>
          : null
        }
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
          <Space>
            <Button type="default" onClick={backToList}>Cancel</Button>
            <Button type="primary" htmlType="submit">Save</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}