import React from 'react';
import {
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const subFieldLayout = {
  colon: false,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function NodeForm({ form }) {

  const propsValue = Form.useWatch('properties', form);

  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
    >
      <Form.Item
        label="Type"
        name="type"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
      >
        <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
      </Form.Item>
      <Form.Item
        label="Synonyms"
        name="synonyms"
      >
        <TextArea
          autoSize={{ minRows: 1, maxRows: 14 }}
          placeholder="Enter comma-separated list"
        />
      </Form.Item>
      <div
        style={{ fontWeight: 600, marginBottom: 8 }}
      >
        Properties
      </div>
      <Form.Item style={{ height: 174, overflowY: 'auto' }}>
        <Form.List name="properties">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item key={field.name}>
                  <Form.Item
                    {...subFieldLayout}
                    label={index === 0 ? 'Property' : ''}
                    name={[field.name, 'property']}
                    style={{ display: 'inline-block', width: 'calc(50% - 20px)', marginBottom: 0 }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...subFieldLayout}
                    label={index === 0 ? 'Data Type' : ''}
                    name={[field.name, 'dataType']}
                    style={{ display: 'inline-block', width: 'calc(50% - 20px)', marginBottom: 0, marginLeft: 8 }}
                  >
                    <Select
                      allowClear
                      options={[
                        {
                          label: 'Tag',
                          value: 'tag',
                        },
                        {
                          label: 'String',
                          value: 'string',
                        },
                        {
                          label: 'String List',
                          value: 'string_list',
                        },
                        {
                          label: 'String Enum',
                          value: 'enum',
                        },
                        {
                          label: 'String List Enum',
                          value: 'list_enum',
                        },
                        {
                          label: 'Integer',
                          value: 'int',
                        },
                        {
                          label: 'Float',
                          value: 'float',
                        },
                        {
                          label: 'Boolean',
                          value: 'boolean',
                        },
                      ]}
                    />
                  </Form.Item>
                  {propsValue?.[index]?.dataType === 'enum' || propsValue?.[index]?.dataType === 'list_enum' ?
                    <Form.Item
                      name={[field.name, 'enum']}
                      style={{ display: 'inline-block', marginBottom: 0, marginTop: 8, width: 'calc(100% - 32px)' }}
                    >
                      <TextArea
                        autoSize={{ minRows: 1, maxRows: 14 }}
                        placeholder="Enter comma-separated list"
                      />
                    </Form.Item>
                    : null
                  }
                  <Form.Item
                    {...subFieldLayout}
                    label={index === 0 ? ' ' : ''}
                    style={{
                      display: 'inline-block',
                      width: '32px',
                      marginBottom: 0,
                      marginTop: propsValue?.[index]?.dataType === 'enum' ? (index === 0 ? -62 : -32) : 0,
                    }}
                  >
                    <Button type="text"
                      icon={<CloseOutlined />}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                </Form.Item>
              ))}
              <Form.Item
                style={{ marginBottom: 0 }}
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '100%', zIndex: 101 }}
                  icon={<PlusOutlined />}
                >
                  Add Property
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
}