import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Segmented,
  Space,
  Switch,
} from 'antd';
import {
  CloseOutlined,
  CommentOutlined,
  PlusOutlined,
  ShareAltOutlined,
  SnippetsOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ReactMarkdown from 'react-markdown';
import isEqual from 'lodash.isequal';
import { v4 as uuidv4 } from 'uuid';

import { ChatView } from '../../components/ChatView';
import GraphView from '../../components/GraphView';
import { TagsInput } from '../../components/TagsInput';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import {
  filterGraph,
  getDataSource,
  getDefaultColumns,
  getLegend,
  getSelectedTypes,
} from '../../utils/graphUtils';
import {
  getGraphAsync,
  getNodesMetadataAsync,
  getRelationshipsMetadataAsync,
  searchGraphAsync,
  selectGraphs,
  selectNodesMetadata,
  selectRelationshipsMetadata,
} from '../graph/graphSlice';
import {
  createSettingAsync,
  getSettingByKeyAsync,
  selectSettings,
  updateSettingAsync,
} from '../settings/settingsSlice';
import {
  createNotebookAsync,
  getNotebookAsync,
  selectNotebooks,
  updateNotebookAsync,
} from './notebooksSlice';

const { TextArea } = Input;

const TAGS_KEY = 'NOTEBOOK_TAGS';

const layout = {
  layout: 'vertical',
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export function Notebook() {

  const [cellsDisplayMode, setCellsDisplayMode] = useState('read');
  const [editMode, setEditMode] = useState({});
  const [previewMode, setPreviewMode] = useState({});
  const [existingTags, setExistingTags] = useState([]);
  const [formData, setFormData] = useState(null);

  const notebooks = useSelector(selectNotebooks);
  const settings = useSelector(selectSettings);
  const nodesMetadata = useSelector(selectNodesMetadata);
  const relationshipsMetadata = useSelector(selectRelationshipsMetadata);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let id = location.pathname.match(/\/notebooks\/(.*)/)[1];
  const isNew = id === 'new';
  const notebook = notebooks[id];

  const nodeOptions = useMemo(() => {
    return Object.keys(nodesMetadata).map(label => ({
      label,
      value: label,
    }))
  }, [nodesMetadata]);

  const relationshipOptions = useMemo(() => {
    return Object.keys(relationshipsMetadata).map(label => ({
      label,
      value: label,
    }))
  }, [relationshipsMetadata]);

  useEffect(() => {
    if (notebook) {
      const cells = notebook.cells?.map(cell => {
        if (cell.cellType === 'graph') {
          const { graph, selectedTypes, ...props } = (cell.graph || {});
          if (graph) {
            return {
              cellType: cell.cellType,
              graph: {
                ...props,
                dataSource: getDataSource(graph),
                defaultColumns: getDefaultColumns(graph),
                graph,
                legend: getLegend(graph),
                selectedTypes: getSelectedTypes(graph, selectedTypes),
              },
            };
          }
        }
        return cell;
      });
      setFormData({ ...notebook, cells });
    }
  }, [notebook]);

  const [form] = Form.useForm();

  const cellsValue = Form.useWatch('cells', form);

  const imagesRef = useRef({});

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Casebook',
    }));
    if (!isNew) {
      dispatch(getNotebookAsync(id));
    }
  }, []);

  useEffect(() => {
    if (selectedWorkspace) {
      const workspaceId = selectedWorkspace.id;
      dispatch(getSettingByKeyAsync({ key: TAGS_KEY, workspaceId }));
      dispatch(getNodesMetadataAsync({ workspaceId }));
      dispatch(getRelationshipsMetadataAsync({ workspaceId }));
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    const tagsNotebook = Object.values(settings).find(s => s.key === TAGS_KEY);
    if (tagsNotebook) {
      setExistingTags(tagsNotebook.value || []);
    }
  }, [settings]);

  const handleImageChange = (index, image) => {
    console.log('image:', index, image);
    imagesRef.current[index] = image;
  };

  const onCancel = () => {
    navigate('/notebooks');
  };

  const onFinish = (values) => {
    const cells = values.cells.map((cell, i) => {
      if (cell.cellType === 'graph') {
        const image = imagesRef.current[i];
        if (image) {
          return {
            ...cell,
            graph: { ...cell.graph, image },
          };
        }
      }
      return cell;
    });
    values = { ...values, cells };
    if (isNew) {
      dispatch(createNotebookAsync({
        values: {
          ...values,
          workspaceId: selectedWorkspace.id,
        }
      }));
    } else {
      dispatch(updateNotebookAsync({ id, values }));
    }
    if (values.tags?.length) {
      updateExistingTags(values.tags);
    }
    navigate('/notebooks');
  };

  const updateExistingTags = (tags) => {
    const notebook = Object.values(notebooks).find(s => s.key === TAGS_KEY);
    const newTags = [...new Set([...existingTags, ...tags])];
    newTags.sort((a, b) => a < b ? -1 : 1);
    const values = {
      key: TAGS_KEY,
      settingType: 'json',
      value: newTags,
      workspaceId: selectedWorkspace.id,
    };
    if (notebook) {
      dispatch(updateSettingAsync({ id: notebook.id, values }));
    } else {
      dispatch(createSettingAsync({ values }));
    }
  };

  function DragHandle({ attributes, listeners }) {
    return (
      <div style={{ paddingTop: 1 }}>
        <button className="drag-handle" {...listeners} {...attributes}>
          <svg viewBox="0 0 20 20" width="12">
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
          </svg>
        </button>
      </div>
    );
  }

  function MarkdownInput({ editable, onBlur, onClick, preview, value }) {

    const [val, setVal] = useState(value);

    if (editable) {
      return (
        <div style={{ display: 'flex', gap: 24 }}>
          <TextArea
            autoSize={{ minRows: 2, maxRows: 14 }}
            onBlur={() => { onBlur(val); }}
            onChange={(ev) => { setVal(ev.target.value); }}
            value={val}
            style={{ width: preview ? 'calc(50% - 12px)' : '100%' }}
          />
          {preview ?
            <ReactMarkdown className="markdown">
              {val}
            </ReactMarkdown>
            : null
          }
        </div>
      );
    } else {
      return (
        <div onClick={onClick} style={{ cursor: 'pointer' }} title="Click to edit">
          <ReactMarkdown className="markdown">
            {val}
          </ReactMarkdown>
        </div>
      );
    }
  }

  function GraphInput({ index, onChange, onImageChange, value, ...props }) {

    const [updatedProps, setUpdatedProps] = useState(null);

    const graphs = useSelector(selectGraphs);

    const dispatch = useDispatch();

    useEffect(() => {
      if (updatedProps) {
        const { correlationId, selectedTypes, ...props } = updatedProps;
        const graph = graphs[correlationId];
        if (graph) {
          const types = getSelectedTypes(graph, selectedTypes);
          onChange({
            ...props,
            correlationId: uuidv4(),
            dataSource: getDataSource(graph),
            defaultColumns: getDefaultColumns(graph),
            graph,
            legend: getLegend(graph),
            selectedTypes: types,
          });
          setUpdatedProps(null);
        }
      }
    }, [graphs]);

    const hasNewType = useCallback((selectedTypes) => {
      for (const [label, selected] of Object.entries(selectedTypes)) {
        if (selected && !value?.selectedTypes[label]) {
          return true;
        }
      }
      return false;
    }, []);

    const handleChange = ({ graph, query, selectedTypes, ...props }) => {
      if (!isEqual(query, value?.query) || hasNewType(selectedTypes)) {
        const correlationId = uuidv4();
        if (query) {
          console.log('dispatch searchGraphAsync [correlationId=%s, query=%s]', correlationId, query);
          dispatch(searchGraphAsync({ correlationId, query, workspaceId: selectedWorkspace.id }));
        } else {
          console.log('dispatch getGraphAsync [correlationId=%s]', correlationId);
          dispatch(getGraphAsync({ correlationId, workspaceId: selectedWorkspace.id }));
        }
        setUpdatedProps({ ...props, correlationId, query, selectedTypes });
      } else {
        onChange({
          ...props,
          graph: filterGraph(value?.graph, selectedTypes),
          query,
          selectedTypes,
        });
      }
    };

    if (updatedProps) {
      return (
        <div>Updating...</div>
      );
    }
    return (
      <GraphView
        {...props}
        height={700}
        searchBarPaddingLeft={0}
        onChange={handleChange}
        onImageChange={(image) => onImageChange(index, image)}
        value={value}
        nodeOptions={nodeOptions}
        relationshipOptions={relationshipOptions}
      />
    );
  }

  function SortableItem({ field, index, remove }) {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: field.key,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      marginBottom: 16,
    };

    return (
      <Row ref={setNodeRef} style={style}>
        <Col span={24} style={{ border: '1px solid #E2E8F0', padding: '16px 8px' }}>
          <Form.Item key={field.key}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <DragHandle attributes={attributes} listeners={listeners} />
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 8 }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <label>Cell Type</label>
                    <Form.Item noStyle
                      name={[field.name, 'cellType']}
                      style={{ marginBottom: 0 }}
                      initialValue={'note'}
                    >
                      <Segmented
                        size="small"
                        style={{ background: 'rgba(0, 0, 0, 0.25)', display: 'inline-flex' }}
                        options={[
                          {
                            label: <SnippetsOutlined />,
                            value: 'note',
                          },
                          {
                            label: <ShareAltOutlined />,
                            value: 'graph',
                          },
                          {
                            label: <TableOutlined />,
                            value: 'table',
                          },
                          {
                            label: <CommentOutlined />,
                            value: 'query',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                  {cellsValue?.[index]?.cellType === 'note' ?
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <label>Preview</label>
                      <Switch
                        onChange={(checked) => { setPreviewMode(cur => ({ ...cur, [index]: checked })); }}
                        size="small"
                        value={previewMode[index]}
                      />
                    </div>
                    : null
                  }
                </div>
                {cellsValue?.[index]?.cellType === 'note' ?
                  <Form.Item
                    name={[field.name, 'text']}
                    style={{ marginBottom: 0 }}
                    trigger="onBlur"
                    onBlur={() => { setEditMode(cur => ({ ...cur, [index]: false })); }}
                  >
                    <MarkdownInput
                      editable={editMode[index] || !cellsValue[index]?.text}
                      onClick={() => { setEditMode(cur => ({ ...cur, [index]: true })); }}
                      preview={previewMode[index]}
                    />
                  </Form.Item>
                  : null
                }
                {cellsValue?.[index]?.cellType === 'graph' ?
                  <Form.Item
                    name={[field.name, 'graph']}
                    style={{ marginBottom: 0 }}
                  >
                    <GraphInput
                      index={index}
                      onImageChange={handleImageChange}
                    />
                  </Form.Item>
                  : null
                }
                {cellsValue?.[index]?.cellType === 'table' ?
                  <Form.Item
                    name={[field.name, 'graph']}
                    style={{ marginBottom: 0 }}
                  >
                    <GraphInput
                      defaultLayout="table"
                      index={index}
                      onImageChange={handleImageChange}
                    />
                  </Form.Item>
                  : null
                }
                {cellsValue?.[index]?.cellType === 'query' ?
                  <div style={{ marginTop: 16 }}>
                    <Form.Item
                      name={[field.name, 'messages']}
                      style={{ marginBottom: 0 }}
                    >
                      <ChatView
                        selectMultiple={true}
                        selectable={true}
                        workspaceId={selectedWorkspace.id}
                        domain={selectedWorkspace.key}
                      />
                    </Form.Item>
                  </div>
                  : null
                }
              </div>
              <Button type="text"
                icon={<CloseOutlined />}
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
    );
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function CellsList({ fields, errors, add, move, remove }) {

    const [activeId, setActiveId] = useState(null);

    const handleDragStart = (ev) => {
      setActiveId(ev.active.id);
    };

    const handleDragEnd = ({ active, over }) => {
      setActiveId(null);
      if (active.id !== over.id) {
        const from = fields.findIndex(f => f.key === active.id);
        const to = fields.findIndex(f => f.key === over.id);
        move(from, to);
      }
    }

    return (
      <>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={fields} strategy={verticalListSortingStrategy}>
            {fields.map((field, index) => (
              <SortableItem key={field.key}
                field={field}
                index={index}
                remove={remove}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId ?
              <SortableItem
                field={fields.find(f => f.key === activeId)}
                index={fields.findIndex(f => f.key === activeId)}
                remove={remove}
              />
              : null
            }
          </DragOverlay>
        </DndContext>
        <Form.Item>
          <Button block
            type="dashed"
            onClick={() => add()}
            style={{ zIndex: 101 }}
            icon={<PlusOutlined />}
          >
            Add Cell
          </Button>
          <Form.ErrorList errors={errors} />
        </Form.Item>
      </>
    );
  }

  if (!isNew && !formData) {
    return (
      <div style={{ marginTop: 20 }}>Loading...</div>
    );
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Form
        {...layout}
        form={form}
        name="notebooks"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={formData}
        onKeyDown={ev => ev.key === 'Enter' && ev.preventDefault()}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter a name',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tags"
          name="tags"
        >
          <TagsInput existingTags={existingTags} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
        >
          <TextArea autoSize={{ minRows: 1, maxRows: 14 }} />
        </Form.Item>
        <div style={{ display: 'flex', alignItems: 'center', gap: 24, paddingBottom: 8 }}>
          <div>Cells</div>
          <Segmented
            size="small"
            style={{ background: 'rgba(0, 0, 0, 0.25)', display: 'inline-flex' }}
            onChange={setCellsDisplayMode}
            options={[
              {
                label: 'Read',
                value: 'read',
              },
              {
                label: 'Edit',
                value: 'edit',
              },
            ]}
            value={cellsDisplayMode}
          />
        </div>
        {cellsDisplayMode === 'read' ?
          <div className="cells">
            {notebook?.cells.map((cell, i) => {
              if (cell.cellType === 'note') {
                return (
                  <div className="cell" key={'cell-' + i}>
                    <ReactMarkdown className="markdown">
                      {cell.text}
                    </ReactMarkdown>
                  </div>
                )
              }
              if (cell.cellType === 'graph' && cell.graph?.image) {
                return (
                  <div className="cell" key={'cell-' + i}>
                    <div style={{ fontWeight: 600 }}>{cell.graph?.query}</div>
                    <Image src={cell.graph?.image} width={700} />
                  </div>
                )
              }
              if (cell.cellType === 'table') {
                return (
                  <div className="cell" key={'cell-' + i}>
                    <GraphView
                      defaultLayout="table"
                      height={700}
                      searchBarPaddingLeft={0}
                      value={cell.graph}
                    />
                  </div>
                )
              }
              if (cell.cellType === 'query') {
                return (
                  <div className="cell" key={'cell-' + i}>
                    <div style={{ fontWeight: 600 }}>Message history</div>
                    <div style={{ width: 700 }}>
                      {cell.messages?.map(m => {
                        return (
                          <div key={m.key}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: m.role === 'user' ? 'row-reverse' : 'row',
                              gap: 16,
                            }}
                          >
                            <div className="avatar"><Avatar>
                              {m.role === 'user' ? 'U' : 'A'}
                            </Avatar>
                            </div>
                            <div>
                              {m.role === 'user' ? m.content : m.content[0].content}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              }
            })}
          </div>
          :
          <Form.List name="cells">
            {(fields, { add, move, remove }, { errors }) => (
              <CellsList
                fields={fields}
                add={add}
                move={move}
                remove={remove}
                errors={errors}
              />
            )}
          </Form.List>
        }
        <Form.Item>
          <Space>
            <Button type="default" onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit">Save</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );

}