import { InlineTool } from '@editorjs/editorjs';
import { IconStretch } from '@codexteam/icons';

import { http } from '../../http';

/**
 * Expand Inline Tool
 *
 * Inline Toolbar Tool
 *
 * Makes selected text longer
 */
export default class ExpandInlineTool implements InlineTool {

  api: any;

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @returns {boolean}
   */
  public static isInline = true;

  /**
   * Title for hover-tooltip
   */
  public static title = 'Expand';

  /**
   * Styles
   */
  private readonly CSS = {
    button: 'ce-inline-tool',
    buttonActive: 'ce-inline-tool--active',
    buttonModifier: 'ce-inline-tool--bold',
  };

  /**
   * Elements
   */
  private nodes: { button: HTMLButtonElement | undefined } = {
    button: undefined,
  };

  public constructor({ api }: any) {
    this.api = api;
  }

  /**
   * Create button for Inline Toolbar
   */
  public render(): HTMLElement {
    this.nodes.button = document.createElement('button') as HTMLButtonElement;
    this.nodes.button.type = 'button';
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.innerHTML = IconStretch;

    return this.nodes.button;
  }

  public async surround(range: any): Promise<void> {
    console.log('range:', range);
    const { startOffset, endOffset, commonAncestorContainer } = range;
    const content = commonAncestorContainer.textContent;
    const text = content.slice(startOffset, endOffset);
    const url = '/api/executions/' + 'expand';
    const res = await http.post(url, { args: { text } });
    const index = this.api.blocks.getCurrentBlockIndex();
    const block = this.api.blocks.getBlockByIndex(index);
    await this.api.blocks.update(block.id, { text: res.data });
    this.api.caret.setToBlock(index, 'end');
  }

  public checkState(): boolean {
    return false;
  }

  /**
   * Set a shortcut
   *
   * @returns {boolean}
   */
  static get shortcut(): string {
    return 'CTRL+K';
  }

}
