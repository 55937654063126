import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
} from '@daily-co/daily-react';
import { Typography } from 'antd';

import './Call.css';
import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';
import { selectTranscripts } from '../../botsSlice';

export default function Call({ correlationId }) {
  /* If a participant runs into a getUserMedia() error, we need to warn them. */
  const [getUserMediaError, setGetUserMediaError] = useState(false);

  const transcripts = useSelector(selectTranscripts);

  const transcript = transcripts[correlationId] || [];

  /* We can use the useDailyEvent() hook to listen for daily-js events. Here's a full list
   * of all events: https://docs.daily.co/reference/daily-js/events */
  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  /* This is for displaying remote participants: this includes other humans, but also screen shares. */
  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  /* This is for displaying our self-view. */
  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantIds.length < 1 || screens.length < 1;

  const renderCallScreen = () => (
    <div className={screens.length > 0 ? 'is-screenshare' : 'call'}>
      {/* Your self view */}
      {localSessionId && (
        <Tile
          id={localSessionId}
          isLocal
          isAlone={isAlone}
        />
      )}
      {/* Videos of remote participants and screen shares */}
      {remoteParticipantIds.length > 0 || screens.length > 0 ? (
        <>
          {remoteParticipantIds.map((id) => (
            <Tile key={id} id={id} />
          ))}
          {screens.map((screen) => (
            <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
          ))}
        </>
      ) : (
        // When there are no remote participants or screen shares
        <div className="info-box">
          <h1>Waiting for the PQ AI Candidate to join.</h1>
          {/* <p>Invite someone by sharing this link:</p>
          <span className="room-url">{window.location.href}</span> */}
        </div>
      )}
      <div id="transcript"
        style={{
          background: '#e9e7e7',
          // height: 'calc((100vh - 132px) / 2)',
          overflowY: 'auto',
          padding: 16,
          // position: 'absolute',
          // right: 0,
          // top: 64,
          // width: 360,
          width: '100%',
          height: '100%',
        }}
      >
        <div style={{ fontWeight: 600 }}>Transcript</div>
        <div>
          {transcript.map((t, i) => (
            <div key={'text-' + i}>
              <Typography.Paragraph>{t}</Typography.Paragraph>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
}
