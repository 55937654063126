import { http } from '../../http';

import { WandIcon } from './icons';

export default class Autocomplete {

  static get isTune() {
    return true;
  }

  constructor({ data, api, block }) {
    this.api = api;
    this.block = block;
    this.data = data;
  }

  render() {
    return {
      icon: WandIcon,
      label: 'Complete',
      onActivate: async () => {
        try {
          const value = await this.block.save();
          let text = value.data.text;
          const startpos = text.length;
          const url = '/api/autocomplete';
          const res = await http.post(url, { content: text });
          let completion = res.data.choices[0].message.content.trim();
          if (completion.startsWith(text)) {
            completion = completion.slice(text.length);
          }
          if (!/\s|&nbsp;$/.test(text)) {
            text += ' ';
          }
          text += '<mark class="cdx-marker">' + completion + '</mark>';
          await this.api.blocks.update(this.block.id, { text });
          console.log('!!', this.api.blocks.getCurrentBlockIndex(), startpos);
          this.api.caret.focus();
          this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex(), 'start', startpos);
          console.log('here');
          this.api.toolbar.close();
          this.block.dispatchChange();
        } catch (err) {
          console.error(err, err.stack);
          throw err;
        }
      }
    };
  }

  // renderSettings() {
  //   const settings = [
  //     {
  //       name: 'autocomplete',
  //       icon,
  //     },
  //   ];
  //   const wrapper = document.createElement('div');

  //   settings.forEach(tune => {
  //     let button = document.createElement('div');

  //     button.classList.add('cdx-settings-button');
  //     button.innerHTML = tune.icon;
  //     wrapper.appendChild(button);
  //   });

  //   return wrapper;
  // }

  // wrap(blockContent) {
  //   const myWrapper = document.createElement('div');

  //   myWrapper.append(blockContent);

  //   myWrapper.style.fontSize = '2em';

  //   return myWrapper;
  // }

  save(blockContent) {
    return blockContent;
  }

}
