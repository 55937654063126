import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const ontologiesSlice = createSlice({
  name: 'ontologies',
  initialState: {
    loaded: false,
    loading: false,
    ontologies: {},
  },
  reducers: {
    removeOntologies: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.ontologies[id];
      }
    },
    setOntologies: (state, action) => {
      for (const ontology of action.payload.ontologies) {
        state.ontologies[ontology.id] = ontology;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    }
  }
});

export const {
  removeOntologies,
  setOntologies,
  startLoad,
} = ontologiesSlice.actions;

export const getOntologiesAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/ontologies?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setOntologies({ ontologies: res.data }));
};

export const getOntologyAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/ontologies/${id}`;
  const res = await http.get(url);
  dispatch(setOntologies({ ontologies: [res.data] }));
};

export const createOntologyAsync = ({ values }) => async (dispatch) => {
  const url = '/api/ontologies';
  const res = await http.post(url, values);
  dispatch(setOntologies({ ontologies: [res.data] }));
};

export const updateOntologyAsync = ({ id, values }) => async (dispatch) => {
  const url = `/api/ontologies/${id}`;
  const res = await http.put(url, values);
  dispatch(setOntologies({ ontologies: [res.data] }));
};

export const deleteOntologiesAsync = (ids) => async (dispatch) => {
  const url = `/api/ontologies?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeOntologies({ ids }));
};

export const selectOntologies = (state) => state.ontologies.ontologies;

export const selectLoaded = (state) => state.ontologies.loaded;

export const selectLoading = (state) => state.ontologies.loading;

export default ontologiesSlice.reducer;
