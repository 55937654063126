import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { http } from '../../http';
import { pollStatus } from '../../utils';

export const inferenceRunsSlice = createSlice({
  name: 'inferenceRuns',
  initialState: {
    correlationId: null,
    loaded: false,
    loading: false,
    inferenceRuns: {},
    processing: false,
    processed: false,
  },
  reducers: {
    processed: (state) => {
      state.processed = true;
      state.processing = false;
    },
    removeInferenceRuns: (state, action) => {
      for (const id of action.payload.ids) {
        delete state.inferenceRuns[id];
      }
    },
    resetInferenceRuns: (state) => {
      state.inferenceRuns = {};
    },
    setCorrelationId: (state, action) => {
      state.correlationId = action.payload.correlationId;
    },
    setInferenceRuns: (state, action) => {
      for (const run of action.payload.inferenceRuns) {
        state.inferenceRuns[run.id] = run;
      }
      state.loaded = true;
      state.loading = false;
    },
    startLoad: (state) => {
      state.loaded = false;
      state.loading = true;
    },
    startProcessing: (state) => {
      state.processed = false;
      state.processing = true;
    },
  }
});

export const {
  processed,
  removeInferenceRuns,
  resetInferenceRuns,
  setCorrelationId,
  setInferenceRuns,
  startLoad,
  startProcessing,
} = inferenceRunsSlice.actions;

export const getInferenceRunsAsync = ({ workspaceId }) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/inference-runs?workspace_id=${workspaceId}`;
  const res = await http.get(url);
  dispatch(setInferenceRuns({ inferenceRuns: res.data }));
};

export const getInferenceRunAsync = (id) => async (dispatch) => {
  dispatch(startLoad());
  const url = `/api/inference-runs/${id}`;
  const res = await http.get(url);
  dispatch(setInferenceRuns({ inferenceRuns: [res.data] }));
};

export const createInferenceRunAsync = ({ correlationId, values }) => async (dispatch) => {
  const url = '/api/inference-runs';
  const res = await http.post(url, values);
  dispatch(setInferenceRuns({ inferenceRuns: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const updateInferenceRunAsync = ({ correlationId, id, values }) => async (dispatch) => {
  const url = `/api/inference-runs/${id}`;
  const res = await http.put(url, values);
  dispatch(setInferenceRuns({ inferenceRuns: [res.data] }));
  dispatch(setCorrelationId({ correlationId }));
};

export const deleteInferenceRunsAsync = (ids) => async (dispatch) => {
  const url = `/api/inference-runs?ids=${ids.join(',')}`;
  await http.delete(url);
  dispatch(removeInferenceRuns({ ids }));
};

export const runInferenceAsync = ({ activity, correlationId, nodeLabels, setting, workspaceId, domain }) => async (dispatch) => {
  dispatch(startProcessing());
  const correlationId = uuidv4();
  let url;
  if (activity === 'extract-obligations') {
    url = '/api/activities/extract-obligations';
  } else if (activity === 'extract-topics') {
    url = '/api/activities/extract-topics';
  } else if (activity === 'extract-thesis-statement') {
    url = '/api/education-activities/extract-thesis-statement';
  } else {
    throw new Error(`Invalid activity: ${activity}`);
  }
  const res = await http.post(url, {
    correlationId,
    nodeLabels,
    setting,
    workspaceId,
    domain,
  });
  pollStatus(http, correlationId, () => {
    dispatch(processed());
  });
}

export const selectCorrelationId = (state) => state.inferenceRuns.correlationId;

export const selectInferenceRuns = (state) => state.inferenceRuns.inferenceRuns;

export const selectLoaded = (state) => state.inferenceRuns.loaded;

export const selectLoading = (state) => state.inferenceRuns.loading;

export const selectProcessed = (state) => state.inferenceRuns.processed;

export const selectProcessing = (state) => state.inferenceRuns.processing;

export default inferenceRunsSlice.reducer;
