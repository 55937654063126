import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { Chat } from '../../components/Chat';
import { ContentView } from '../../components/ContentView';
import NavbarContext from '../../contexts/NavbarContext';
import WorkspaceContext from '../../contexts/WorkspaceContext';
import { getExtension } from '../../pathUtils';
import {
  getUploadForObjectNameAsync,
  selectUploads,
} from '../uploader/fileUploaderSlice';

import {
  queryAsync,
  selectLoading,
  selectMessages,
  setMessages,
} from './chatSlice';

export function AppChat() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [upload, setUpload] = useState(null);

  const loading = useSelector(selectLoading);
  const messages = useSelector(selectMessages);
  const uploads = useSelector(selectUploads);

  const { setNavbarState } = useContext(NavbarContext);
  const { selectedWorkspace } = useContext(WorkspaceContext);

  const dispatch = useDispatch();

  const ext = useMemo(() => {
    if (!upload) return null;
    getExtension(upload.filename);
  }, [upload]);

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: 'Query Knowledge Base',
    }));
  }, []);

  useEffect(() => {
    if (selectedObject) {
      const upload = uploads.find(u => u.objectName === selectedObject);
      if (upload) {
        setUpload(upload);
        setIsModalOpen(true);
        setSelectedObject(null);
      }
    }
  }, [uploads]);

  const handleChatSubmit = (values) => {
    const { messages } = values;
    const content = messages[messages.length - 1].content;
    let args = {
      messages,
      q: content,
      workspaceId: selectedWorkspace.id,
      domain: selectedWorkspace.key,
    };
    dispatch(queryAsync(args));
  };

  const handleReset = () => {
    dispatch(setMessages({ messages: [] }));
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setUpload(null);
  };

  const showContent = (objectName) => {
    dispatch(getUploadForObjectNameAsync({
      objectName,
      workspaceId: selectedWorkspace.id,
    }));
    setSelectedObject(objectName);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        title="Content Preview"
        width={ext === 'pdf' ? 643 : '70%'}
        styles={{
          body: { height: 500, overflowY: 'auto' },
        }}
        onCancel={onCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
      >
        <ContentView upload={upload} />
      </Modal>
      <div style={{ marginTop: 20, padding: 16 }}>
        <Chat
          enableActions
          loading={loading}
          messages={messages}
          onSubmit={handleChatSubmit}
          placeholder={messages.length ? 'Reply...' : 'Query the knowledge base using plain language...'}
          onReset={handleReset}
          onShowCitation={showContent}
        />
      </div>
    </>
  )
}