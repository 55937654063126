import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Space } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

const { Meta } = Card;

export function ToolInput({ onChange, value, allowedTools }) {
  return (
    <Space id="tools" wrap>
      {!allowedTools || allowedTools.includes('summarize') ?
        <Card
          onClick={() => onChange('summarize')}
          style={{
            background: (value === 'summarize') ? '#eee' : 'inherit',
            cursor: 'pointer',
            width: 250,
          }}
        >
          <Meta
            avatar={
              <Avatar icon={<ToolOutlined />}
              >
              </Avatar>
            }
            title={<Link>Summarize</Link>}
            description={'Summarize the results.'}
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
          />
        </Card>
        : null
      }
      {!allowedTools || allowedTools.includes('suggest_courses') ?
        <Card
          onClick={() => onChange('suggest_courses')}
          style={{
            background: (value === 'suggest_courses') ? '#eee' : 'inherit',
            cursor: 'pointer',
            width: 250,
          }}
        >
          <Meta
            avatar={
              <Avatar icon={<ToolOutlined />}
              >
              </Avatar>
            }
            title={<Link>Suggest Courses</Link>}
            description={'Suggest potential courses from the content.'}
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
          />
        </Card>
        : null
      }
      {!allowedTools || allowedTools.includes('suggest_learning_objectives') ?
        <Card
          onClick={() => onChange('suggest_learning_objectives')}
          style={{
            background: (value === 'suggest_learning_objectives') ? '#eee' : 'inherit',
            cursor: 'pointer',
            width: 250,
          }}
        >
          <Meta
            avatar={
              <Avatar icon={<ToolOutlined />}
              >
              </Avatar>
            }
            title={<Link>Learning Objectives</Link>}
            description={'Suggest potential learning objectives from the content.'}
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
          />
        </Card>
        : null
      }
      {!allowedTools || allowedTools.includes('write_course_chapter') ?
        <Card
          onClick={() => onChange('write_course_chapter')}
          style={{
            background: (value === 'write_course_chapter') ? '#eee' : 'inherit',
            cursor: 'pointer',
            width: 250,
          }}
        >
          <Meta
            avatar={
              <Avatar icon={<ToolOutlined />}
              >
              </Avatar>
            }
            title={<Link>Write Course Chapter</Link>}
            description={'Write a course chapter from the content.'}
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
          />
        </Card>
        : null
      }
      {!allowedTools || allowedTools.includes('generate_report') ?
        <Card
          onClick={() => onChange('generate_report')}
          style={{
            background: (value === 'generate_report') ? '#eee' : 'inherit',
            cursor: 'pointer',
            width: 250,
          }}
        >
          <Meta
            avatar={
              <Avatar icon={<ToolOutlined />}
              >
              </Avatar>
            }
            title={<Link>Generate Report</Link>}
            description={'Generate a report from the content outline.'}
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
          />
        </Card>
        : null
      }
    </Space>
  )
}

