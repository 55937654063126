import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'antd';

import NavbarContext from '../../contexts/NavbarContext';

export function Home() {

  const { setNavbarState } = useContext(NavbarContext);

  const navigate = useNavigate();

  const [current, setCurrent] = useState(null);

  const onChange = (value) => {
    if (value === 0) {
      navigate('/uploads');
    } else if (value === 1) {
      navigate('/inference');
    } else if (value === 2) {
      navigate('/graph');
    } else if (value === 3) {
      navigate('/artefacts');
    }
    setCurrent(value);
  };

  useEffect(() => {
    setNavbarState((state) => ({
      ...state,
      createLink: null,
      title: '',
    }));
  }, []);

  return (
    <div style={{ paddingTop: 40, paddingLeft: 40 }}>
      <Steps
        direction="vertical"
        size="large"
        current={current}
        onChange={onChange}
        items={[
          {
            title: 'Acquire',
            description: 'Acquire knowledge from data sources',
          },
          {
            title: 'Augment',
            description: 'Infer entities and relationships to enhance the knowledge base',
          },
          {
            title: 'Analyze',
            description: 'Explore and query the knowledge base',
          },
          {
            title: 'Generate Artefacts',
            description: 'Create artefacts and metrics',
          },
        ]}
      />
    </div>
  )
}