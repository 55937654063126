import { createSlice } from '@reduxjs/toolkit';

import { http } from '../../http';

export const botsSlice = createSlice({
  name: 'bots',
  initialState: {
    transcripts: {},
  },
  reducers: {
    setTranscripts: (state, action) => {
      const { correlationId, transcript } = action.payload;
      state.transcripts[correlationId] = transcript;
    },
  }
});

export const {
  setTranscripts,
} = botsSlice.actions;

export const startBotAsync = ({ persona, correlationId }) => async (dispatch) => {
  const url = `/api/bots/${persona}?correlation_id=${correlationId}`;
  await http.post(url);
  const timeout = 120000;
  const start = new Date();
  console.log('Start polling...');
  const intervalId = setInterval(async () => {
    try {
      let res = await http.get('/api/interviews/transcription?correlation_id=' + correlationId);
      console.log('res:', res.data);
      dispatch(setTranscripts({ correlationId, transcript: res.data }));
    } catch (err) {
      console.error('Error while getting latest transcription lines:', err);
      // 423 - locked ~ not ready
      if (err.response?.status !== 423) {
        clearInterval(intervalId);
      } else {
        const now = new Date();
        const diff = now - start;
        if (diff > timeout) {
          clearInterval(intervalId);
        }
      }
    }
  }, 5000);
};

export const getTranscriptAsync = ({ correlationId }) => async (dispatch) => {
  const url = `/api/interviews/transcription?correlation_id=${correlationId}`;
  const res = await http.get(url);
}

export const selectTranscripts = (state) => state.bots.transcripts;

export default botsSlice.reducer;
